<template>
  <modal :title="popUpTitleMsg" :active="active" v-on:close="close">
    <!-- <div class="b1" :popUpTitleMsg="popUpTitleMsg">{{popUpTitleMsg}}</div> -->
    <p :confirmMsg="popUpMsg" class="b1">
      {{ popUpMsg }}
    </p>
    <template v-slot:footer>
      <diyobo-button
        type="secondary"
        :txt="cancelText"
        v-on:click="close(true)"
      />
      <diyobo-button type="primary" :txt="confirmText" v-on:click="submit" />
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import DiyoboCheckbox from "@/components/DiyoboCheckbox.vue";

export default {
  name: "confirm-modal",
  components: {
    Modal,
    DiyoboButton,
    DiyoboCheckbox
  },
  props: {
    popUpMsg: String,
    popUpTitleMsg: String,
    cancelText: { type: String, default: "Cancel" },
    confirmText: { type: String, default: "Confirm" }
  },
  data() {
    return {
      active: false,
      cbFunc: false
    };
  },
  methods: {
    open(args) {
      // this.$refs.modal.style.display = 'block'
      if (args && args.cb) {
        this.cbFunc = args.cb;
      }
      document.body.style.overflow = "hidden";
      setTimeout(() => {
        this.active = true;
      }, 250);
    },
    close(val) {
      this.active = false;
      setTimeout(() => {
        // this.$refs.modal.removeAttribute('style')
        document.body.style.position = "relative";
      }, 500);
      if (val) {
        this.$emit("cancel-method");
      }
    },
    submit() {
      this.$emit("confirm-method");
      if (this.cbFunc) {
        this.cbFunc();
      }
      this.close();
    }
  }
};
</script>

<style lang="less" scoped>
.modal {
  .modal-inner {
    .modal-dialog {
      .modal-body {
        .keep-listed {
          padding-top: 10px;
        }
      }
    }
  }
}

button {
  font-size: 16px;
}
</style>