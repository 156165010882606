<template>
  <div class="added-itinerary">
    <div
      class="itinerary-wrapper"
      v-for="(itinerary, index) in displayedIntineraries"
      :key="itinerary.id"
    >
      <div class="inner-wrapper">
        <div class="times-icon" v-on:click="$emit('removeItinerary', itinerary.id)">
          <font-awesome-icon icon="times" />
        </div>
        <div class="itinerary-title">
          <div class="itinerary-num">
            <p>{{ index + 1 }}</p>
          </div>
          <div class="itinerary-title">
            <p>{{ itinerary.name }}</p>
          </div>
        </div>
        <div class="itinerary-info">
          <div class="itinerary-date">
            <date-slider :info="itinerary.dateArray" :indexOfObj="index" />
          </div>
          <button class="edit-btn" v-on:click="$emit('click', itinerary.id)">Edit</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
div.added-itinerary {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.5em;
  width: 100%;
  //padding-left: 14.5px;
  //padding-left: 5.5px;
  p {
    font-size: 15px !important;
  }
  .itinerary-wrapper {
    position: relative;
    display: flex;
    .inner-wrapper {
      position: relative;
      display: flex;
      //margin-right: 20px;
      margin-right: 13px;
      // margin-bottom: 10px;
      flex-wrap: wrap;
      justify-content: center;
      //width: 300px;
      width: 262px;
      height: 302px;
      -webkit-box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.75);
      // margin-top: 15px;
      margin-bottom: 1.3em;
      .itinerary-title {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .itinerary-num {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 35px;
          height: 35px;
          border-radius: 20px;
          margin: 10px auto 8px auto;
          background-color: #4cac55;
        }
        .itinerary-title {
          width: 100%;
          color: #4cac55;
        }
      }

      .itinerary-info {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        .itinerary-date {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          width: 100%;
          .itinerary-time-title {
            display: flex;
            justify-content: center;
            width: 100%;
          }
          .itinerary-section {
            margin-bottom: 10px;
            width: 100%;
            .itinerary-time-info,
            .itinerary-time-hours {
              display: flex;
              justify-content: center;
              width: 100%;
              margin: 0px;
            }
          }
        }
      }
      .edit-btn {
        background-color: #4cac55;
        color: white;
        font-size: 15px;
        border: none;
        width: 150px;
        height: 30px;
        margin: 15px;
        padding: 5px 40px;
        cursor: pointer;
      }
      .times-icon {
        font-size: 20px;
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        z-index: 10;
      }
    }
  }
}
</style>

<script>
import DiyoboInput from "@/components/DiyoboInput.vue";
import dateFormat from "dateformat";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDown,
  faCalendarAlt,
  faClock,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Vue from "vue";
import DateSlider from "./DateSlider.vue";

library.add(faAngleDown);
library.add(faCalendarAlt);
library.add(faClock);
library.add(faTimes);
export default {
  components: {
    FontAwesomeIcon,
    DateSlider
  },
  props: {
    itineraries: Array,
    index: Number
  },
  data() {
    return {
      displayedIntineraries: this.itineraries
    };
  },
  mounted() {
    console.log(this.myItinerary);
  },
  methods: {
    displayTime(dateInput) {
      let date, Time;
      if (typeof dateInput != "object") {
        date = dateFormat(Date.parse(dateInput), "ddd, mmm dS, yyyy");
        Time = dateFormat(Date.parse(dateInput), "h:MM TT");
        // } else if (typeof this.myItinerary.startTime == "number") {
        //   date = dateFormat(new Date(dateInput), "ddd, mmm dS, yyyy");
        //   Time = dateFormat(new Date(dateInput), "h:MM TT");
      } else {
        date = dateFormat(
          new Date(parseInt(dateInput.$date.$numberLong)),
          "ddd, mmm dS, yyyy"
        );
        Time = dateFormat(
          new Date(parseInt(dateInput.$date.$numberLong)),
          "h:MM TT"
        );
      }
      return `${date}, ${Time}`;
    }
  },
  computed: {
    anyItineraries() {
      return this.displayedIntineraries.length == 0;
    }
  }
};
</script>
