<template>
  <div class="event-calendar" :id="`${idToUse}`">
    <label class="calendar-label" :class="{ error: error }">
      Event Calendar
      <b>*</b>
    </label>
    <div
      class="calendar-container"
      :class="{ error: error }"
      v-for="date in dates"
      :key="date.month"
    >
      <div class="month-wrapper">
        <span>{{ date.month }}</span>
      </div>
      <div class="days-wrapper">
        <div class="day">Sun</div>
        <div class="day">Mon</div>
        <div class="day">Tue</div>
        <div class="day">Wed</div>
        <div class="day">Thu</div>
        <div class="day">Fri</div>
        <div class="day">Sat</div>
      </div>
      <div class="grid">
        <div class="week" v-for="week in date.weeks" :key="week[0]">
          <div
            :class="
              eventDate(week[0], date.daysToCheck)
                ? `day active data`
                : `day inactive data`
            "
            v-on:click="selectRange($event, date)"
            :id="`${date.month}-${week[0]}-${date.year}`"
          >
            {{ week[0] }}
            <div class="hover-wrapper" v-if="showTimes">
              <div
                class="info-hover"
                :id="`${date.month}-${week[0]}-${date.year}-info`"
              >
                <div class="info-inner">
                  <p v-if="checkDay(week[0], date.daysToCheck, 'first')">
                    Start: {{ eventStartTime }}
                  </p>
                  <p v-else>Start: 12:00 AM</p>
                  <p v-if="checkDay(week[0], date.daysToCheck, 'last')">
                    End: {{ eventEndTime }}
                  </p>
                  <p v-else>End: 11:59 PM</p>
                </div>
              </div>
              <div class="pointer"></div>
            </div>
          </div>
          <div
            :class="
              eventDate(week[1], date.daysToCheck)
                ? `day active data`
                : `day inactive data`
            "
            v-on:click="selectRange($event, date)"
            :id="`${date.month}-${week[1]}-${date.year}`"
          >
            {{ week[1] }}
            <div class="hover-wrapper" v-if="showTimes">
              <div
                class="info-hover"
                :id="`${date.month}-${week[1]}-${date.year}-info`"
              >
                <div class="info-inner">
                  <p v-if="checkDay(week[1], date.daysToCheck, 'first')">
                    Start: {{ eventStartTime }}
                  </p>
                  <p v-else>Start: 12:00 AM</p>
                  <p v-if="checkDay(week[1], date.daysToCheck, 'last')">
                    End: {{ eventEndTime }}
                  </p>
                  <p v-else>End: 11:59 PM</p>
                </div>
              </div>
              <div class="pointer"></div>
            </div>
          </div>
          <div
            :class="
              eventDate(week[2], date.daysToCheck)
                ? `day active data`
                : `day inactive data`
            "
            v-on:click="selectRange($event, date)"
            :id="`${date.month}-${week[2]}-${date.year}`"
          >
            {{ week[2] }}
            <div class="hover-wrapper" v-if="showTimes">
              <div
                class="info-hover"
                :id="`${date.month}-${week[2]}-${date.year}-info`"
              >
                <div class="info-inner">
                  <p v-if="checkDay(week[2], date.daysToCheck, 'first')">
                    Start: {{ eventStartTime }}
                  </p>
                  <p v-else>Start: 12:00 AM</p>
                  <p v-if="checkDay(week[2], date.daysToCheck, 'last')">
                    End: {{ eventEndTime }}
                  </p>
                  <p v-else>End: 11:59 PM</p>
                </div>
              </div>
              <div class="pointer"></div>
            </div>
          </div>
          <div
            :class="
              eventDate(week[3], date.daysToCheck)
                ? `day active data`
                : `day inactive data`
            "
            v-on:click="selectRange($event, date)"
            :id="`${date.month}-${week[3]}-${date.year}`"
          >
            {{ week[3] }}
            <div class="hover-wrapper" v-if="showTimes">
              <div
                class="info-hover"
                :id="`${date.month}-${week[3]}-${date.year}-info`"
              >
                <div class="info-inner">
                  <p v-if="checkDay(week[3], date.daysToCheck, 'first')">
                    Start: {{ eventStartTime }}
                  </p>
                  <p v-else>Start: 12:00 AM</p>
                  <p v-if="checkDay(week[3], date.daysToCheck, 'last')">
                    End: {{ eventEndTime }}
                  </p>
                  <p v-else>End: 11:59 PM</p>
                </div>
              </div>
              <div class="pointer"></div>
            </div>
          </div>
          <div
            :class="
              eventDate(week[4], date.daysToCheck)
                ? `day active data`
                : `day inactive data`
            "
            v-on:click="selectRange($event, date)"
            :id="`${date.month}-${week[4]}-${date.year}`"
          >
            {{ week[4] }}
            <div class="hover-wrapper" v-if="showTimes">
              <div
                class="info-hover"
                :id="`${date.month}-${week[4]}-${date.year}-info`"
              >
                <div class="info-inner">
                  <p v-if="checkDay(week[4], date.daysToCheck, 'first')">
                    Start: {{ eventStartTime }}
                  </p>
                  <p v-else>Start: 12:00 AM</p>
                  <p v-if="checkDay(week[4], date.daysToCheck, 'last')">
                    End: {{ eventEndTime }}
                  </p>
                  <p v-else>End: 11:59 PM</p>
                </div>
              </div>
              <div class="pointer"></div>
            </div>
          </div>
          <div
            :class="
              eventDate(week[5], date.daysToCheck)
                ? `day active data`
                : `day inactive data`
            "
            v-on:click="selectRange($event, date)"
            :id="`${date.month}-${week[5]}-${date.year}`"
          >
            {{ week[5] }}
            <div class="hover-wrapper" v-if="showTimes">
              <div
                class="info-hover"
                :id="`${date.month}-${week[5]}-${date.year}-info`"
              >
                <div class="info-inner">
                  <p v-if="checkDay(week[5], date.daysToCheck, 'first')">
                    Start: {{ eventStartTime }}
                  </p>
                  <p v-else>Start: 12:00 AM</p>
                  <p v-if="checkDay(week[5], date.daysToCheck, 'last')">
                    End: {{ eventEndTime }}
                  </p>
                  <p v-else>End: 11:59 PM</p>
                </div>
              </div>
              <div class="pointer"></div>
            </div>
          </div>
          <div
            :class="
              eventDate(week[6], date.daysToCheck)
                ? `day active data`
                : `day inactive data`
            "
            v-on:click="selectRange($event, date)"
            :id="`${date.month}-${week[6]}-${date.year}`"
          >
            {{ week[6] }}
            <div class="hover-wrapper" v-if="showTimes">
              <div
                class="info-hover"
                :id="`${date.month}-${week[6]}-${date.year}-info`"
              >
                <div class="info-inner">
                  <p v-if="checkDay(week[6], date.daysToCheck, 'first')">
                    Start: {{ eventStartTime }}
                  </p>
                  <p v-else>Start: 12:00 AM</p>
                  <p v-if="checkDay(week[6], date.daysToCheck, 'last')">
                    End: {{ eventEndTime }}
                  </p>
                  <p v-else>End: 11:59 PM</p>
                </div>
              </div>
              <div class="pointer"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.event-calendar {
  display: flex;
  flex-wrap: wrap;
  //width: 420px;

  .calendar-label {
    display: block;
    flex-basis: 100%;
    margin-bottom: 0.5em;
    font-size: 16px;
    font-weight: 200;
    &.error {
      color: #e60022;
    }
    b {
      padding-left: 2px;
      color: @colors[input-required];
    }
  }

  .calendar-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-right: 17.3px;
    margin-bottom: 17.3px;
    //width: 260px;
    width: 100%;
    height: 100%;
    border: solid 1px grey;

    &.error {
      border-color: #e60022;
    }

    &:last-of-type {
      margin-right: 0px;
    }

    .month-wrapper {
      width: calc(100% - 6px);
      display: flex;
      align-items: center;
      background-color: #212121;
      padding: 4px 5px;
      border: solid 1px #4e4e4e;
      height: 25px;
      width: 100%;
      font-size: 20px;
    }

    .days-wrapper {
      display: flex;
      width: 100%;
      background-color: #212121;

      .day {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #4e4e4e;
        width: 14.28%;
        height: 25px;
        font-size: 18px;
      }
    }

    .grid {
      display: flex;
      flex-wrap: wrap;
      background-color: #212121;
      width: 100%;

      .week {
        display: flex;
        width: 100%;

        .day {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #4e4e4e;
          width: 14.28%;
          height: 25px;
          font-size: 16px;
          cursor: pointer;
          background-color: #4a639e;
        }

        .day.active {
          position: relative;
          &:hover {
            .hover-wrapper {
              opacity: 1;
              transition: 0.5s;
              visibility: visible;
            }
          }

          .hover-wrapper {
            display: flex;
            flex-wrap: wrap;
            opacity: 0;
            transition: 0.5s;
            visibility: hidden;
            width: 100px;
            height: 50px;
            position: absolute;
            top: -61px;
            left: 5px;

            .info-hover {
              background-image: linear-gradient(160deg, #4eb95e, #4a639e);
              width: 100%;
              height: 100%;
              border-radius: 10px;
              top: -60px;
              padding: 2px;
              z-index: 5;

              .info-inner {
                border-radius: 10px;
                background-color: #171717e0;
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;
                width: 100%;
                height: 100%;

                p {
                  margin: 0px;
                }
              }
            }

            .pointer {
              background-image: linear-gradient(160deg, #4b7a8e, #4b7194);
              padding: 2px;
              width: 15px;
              height: 15px;
              transform: rotate(-45deg);
              position: absolute;
              bottom: -12px;
              left: 41px;
            }
          }
        }

        .day.inactive {
          background-color: #3a3a3a;
          cursor: default;

          &.error {
            background-color: #1a0306;
          }

          .info-hover {
            display: none;
          }
        }

        .day.selected {
          &.green {
            background-color: #4eb95d;
          }
          &.grey {
            background-color: #3a3a3a;
          }
        }
      }
    }
  }
}
</style>

<script>
import dateFormat from "dateformat";

export default {
  name: "event-calendar",
  components: {},
  props: {
    dates: Array,
    savedDates: Array,
    excludedDates: Array,
    error: Boolean,
    reApplySavedDates: Number,
    eventStartTime: String,
    eventEndTime: String,
    showTimes: Boolean,
    changeColour: String,
    checkForTimings: Boolean,
    Bus: Object,
    UniqueId: String,
  },
  data() {
    return {
      displayedData: [],
      startDates: [],
      datesToClick: [],
      // excludedDatesToUse: this.excludedDates,
      firstSelectedDay: "",
      displayedStartDate: "",
      displayedEndDate: "",
      firstClick: false,
      secondClick: false,
    };
  },
  methods: {
    //event dates is causing us issues
    checkDay(day, datesArr, index) {
      let response = false;

      //console.log(day, datesArr[0], datesArr[datesArr.length - 1], index);

      if (parseInt(day) == parseInt(datesArr[0]) && index == "first") {
        response = true;
        //console.log("start");
      } else if (day == datesArr[datesArr.length - 1] && index == "last") {
        response = true;
        //console.log("end");
      }

      return response;
    },
    eventDate(day, arrayToCompareAgainst) {
      let response = false;

      let dayToFind = arrayToCompareAgainst.find((ele) => ele == parseInt(day));
      if (dayToFind) {
        response = true;
      }
      return response;
    },
    removeSelectedMissingDates() {
      let ourArray = this.dates[0].days;
      let dateObj = {
        month: this.dates[0].month,
        year: this.dates[0].year,
      };
      let ourCalendar = document.querySelector(`#${this.idToUse}`);
      if (ourCalendar) {
        for (let i = 0; i < ourArray.length; i++) {
          let day = +ourArray[i];
          let outOfRange = ourCalendar.querySelector(
            `#${dateObj.month}-${day}-${dateObj.year}`
          );
          if (outOfRange) {
            if (outOfRange.classList.contains("selected")) {
              let savedDateCheck = this.savedDatesToUse[0];
              let dateToCheck = new Date(
                `${dateObj.month} ${day}, ${dateObj.year}`
              );
              let startDateToCheck = new Date(this.displayedStartDate);
              let endDateToCheck = new Date(this.displayedEndDate);
              if (
                dateToCheck.getTime() < startDateToCheck.getTime() ||
                dateToCheck.getTime() > endDateToCheck.getTime()
              ) {
                outOfRange.classList.remove("selected");
                outOfRange.classList.remove(this.backgroundColourClass);
              }
            }
          }
        }
      }
    },
    selectRange(e, date) {
      let self = this;
      let valid = false;
      let dayToUse = e.target.innerHTML.split(" ")[1];
      let ourCalendar = document.querySelector(`#${self.idToUse}`);
      if (ourCalendar) {
        valid = date.daysToCheck.some((ele) => ele == +dayToUse);
        console.log("Emits are asynchrous as you were hoping, damm.");
        if (!e.target.classList.contains("inactive")) {
          if (valid) {
            let newDateObj = {
              month: date.month,
              year: date.year,
              day: dayToUse.trim(),
            };
            let dateUpdate = dateFormat(
              new Date(
                newDateObj.month + newDateObj.day + "," + newDateObj.year
              ),
              "ddd, mmm dd, yyyy"
            );
            this.savedDatesToUse.splice(0, this.savedDatesToUse.length);
            if (!self.firstClick && !self.secondClick) {
              self.firstClick = true;
              self.firstSelectedDay = +dayToUse;
              e.target.classList.add("selected");
              e.target.classList.add(self.backgroundColourClass);
              self.displayedStartDate = dateUpdate;
              self.displayedEndDate = dateUpdate;
              this.savedDatesToUse.push(newDateObj);
            } else if (self.firstClick && !self.secondClick) {
              self.secondClick = true;
              if (self.firstSelectedDay < +dayToUse) {
                if (this.checkForTimings) {
                  this.$emit("dateSelected");
                }
                if (self.firstSelectedDay + 1 === +dayToUse) {
                  let firstObj = { ...newDateObj };
                  firstObj.day = `${self.firstSelectedDay}`;
                  this.savedDatesToUse.push(firstObj);
                  this.savedDatesToUse.push(newDateObj);
                  let inRangeElement = ourCalendar.querySelector(
                    `#${newDateObj.month}-${firstObj.day}-${newDateObj.year}`
                  );
                  inRangeElement.classList.add("selected");
                  inRangeElement.classList.add(self.backgroundColourClass);
                  e.target.classList.add("selected");
                  e.target.classList.add(self.backgroundColourClass);
                } else {
                  self.firstSelectedDay = +dayToUse;
                  this.savedDatesToUse.push(newDateObj);
                  e.target.classList.add("selected");
                  e.target.classList.add(self.backgroundColourClass);
                  self.secondClick = false;
                }
                let firstStartDate = dateFormat(
                  new Date(
                    newDateObj.month +
                      self.firstSelectedDay +
                      "," +
                      newDateObj.year
                  ),
                  "ddd, mmm dd, yyyy"
                );
                let lastDateObj =
                  this.savedDatesToUse[this.savedDatesToUse.length - 1];
                let lastEndDate = dateFormat(
                  new Date(
                    newDateObj.month + lastDateObj.day + "," + newDateObj.year
                  ),
                  "ddd, mmm dd, yyyy"
                );
                self.displayedStartDate = firstStartDate;
                self.displayedEndDate = lastEndDate;
              } else if (self.firstSelectedDay == +dayToUse) {
                self.firstSelectedDay = +dayToUse;
                let newDateArray = this.savedDatesToUse.filter((date) => {
                  if (
                    date.month == newDateObj.month &&
                    date.day == newDateObj.day &&
                    date.year == newDateObj.year
                  ) {
                    return date;
                  }
                });
                this.savedDatesToUse.push(...newDateArray);
                self.displayedStartDate = dateUpdate;
                self.displayedEndDate = dateUpdate;
              } else if (self.firstSelectedDay > +dayToUse) {
                self.firstSelectedDay = +dayToUse;
                self.secondClick = false;
                this.savedDatesToUse.push(newDateObj);
                self.displayedStartDate = dateUpdate;
                self.displayedEndDate = dateUpdate;
                e.target.classList.add("selected");
                e.target.classList.add(self.backgroundColourClass);
              } else {
                console.log("Second click failed idk man");
              }
            } else {
              self.secondClick = false;
              self.firstSelectedDay = +dayToUse;
              e.target.classList.add("selected");
              e.target.classList.add(self.backgroundColourClass);
              self.displayedStartDate = dateUpdate;
              self.displayedEndDate = dateUpdate;
              this.savedDatesToUse.push(newDateObj);
            }
            console.log("I should be after");
            this.removeSelectedMissingDates();
          } else {
            console.log("hey man, wasa");
          }
        }
      }
    },
    showSelectedSaveDates() {
      let self = this;
      let ourCalendar = document.querySelector(`#${self.idToUse}`);
      if (ourCalendar) {
        this.savedDatesToUse.forEach((date) => {
          let dayToUse;
          if (parseInt(date.day) < 10) {
            dayToUse = date.day.replace("0", "");
          } else {
            dayToUse = date.day;
          }
          let stringToUse = `#${date.month}-${dayToUse}-${date.year}`;
          let dayFound = ourCalendar.querySelector(stringToUse);
          if (dayFound) {
            dayFound.classList.add("selected");
            dayFound.classList.add(self.backgroundColourClass);
          }
        });
      }
    },
    showAnyErrors() {
      if (this.error) {
        let ourCalendar = document.querySelector(`#${self.idToUse}`);
        if (ourCalendar) {
          let inactiveDays = ourCalendar.querySelectorAll(".inactive.data");
          for (let i = 0; i < inactiveDays.length; i++) {
            let element = inactiveDays[i];
            element.classList.add("error");
          }
        }
      }
    },
    greyOutExcludedDates() {
      let self = this;
      let ourCalendar = document.querySelector(`#${self.idToUse}`);
      if (ourCalendar) {
        console.log(ourCalendar);
        let days = ourCalendar.getElementsByClassName("day");
        for (let i = 0; i < days.length; i++) {
          let day = days[i];
          if (day) {
            if (day.id) {
              let elementsDate = day.id;
              let dayCheck = elementsDate.split("-")[1];
              let foundDate = false;
              self.excludedDatesToUse.forEach((date) => {
                if (date.day && date.month && date.year) {
                  let stringDateToCheck =
                    date.month + "-" + date.day + "-" + date.year;
                  if (elementsDate == stringDateToCheck) {
                    day.classList.add("inactive");
                    console.log("I was run, so wtf happened?", day, "hi");
                    foundDate = true;
                  }
                }
              });
              if (
                day.classList.contains("inactive") &&
                !foundDate &&
                dayCheck &&
                dayCheck != "undefined"
              ) {
                day.classList.remove("inactive");
              }
            }
          }
        }
      }
    },
    revertToFirstDay() {
      let self = this;
      let arrayToKeep = this.savedDatesToUse.filter((date) => {
        if (+date.day === self.firstSelectedDay) {
          return date;
        }
      });
      let objToUse = this.savedDatesToUse[0];
      let firstStartDate = dateFormat(
        new Date(objToUse.month + objToUse.day + "," + objToUse.year),
        "ddd, mmm dd, yyyy"
      );
      self.displayedStartDate = firstStartDate;
      self.displayedEndDate = firstStartDate;
      //self.secondClick = false;
      this.savedDatesToUse.splice(
        0,
        this.savedDatesToUse.length,
        ...arrayToKeep
      );
      this.removeSelectedMissingDates();
      this.showSelectedSaveDates();
    },
    removeHighlightedDays() {
      let self = this;
      let ourCalendar = document.querySelector(`#${self.idToUse}`);
      if (ourCalendar) {
        let days = ourCalendar.querySelectorAll(".day");
        for (let i = 0; i < days.length; i++) {
          let day = days[i];
          if (day) {
            if (day.classList.contains("selected")) {
              day.classList.remove("selected");
              day.classList.remove(self.backgroundColourClass);
            }
          }
        }
      }
    },
  },
  computed: {
    savedDatesToUse: {
      get() {
        return this.savedDates;
      },
      set(arrayOfSavedDates) {
        console.log('what"s going on?', arrayOfSavedDates);
      },
    },
    backgroundColourClass() {
      if (this.changeColour == "grey") {
        return "grey";
      } else {
        return "green";
      }
    },
    idToUse() {
      return `${this.UniqueId}`;
    },
    excludedDatesToUse() {
      return this.excludedDates;
    },
  },
  watch: {
    dates(data) {
      setTimeout(() => {
        this.showSelectedSaveDates();
        this.showAnyErrors();
      }, 30);
    },
    error(val) {
      let ourCalendar = document.querySelector(`#${self.idToUse}`);
      if (ourCalendar) {
        if (val) {
          let inactiveDays = ourCalendar.querySelectorAll(".inactive.data");
          for (let i = 0; i < inactiveDays.length; i++) {
            let element = inactiveDays[i];
            element.classList.add("error");
          }
        } else {
          let inactiveDays = ourCalendar.querySelectorAll(".error");
          for (let i = 0; i < inactiveDays.length; i++) {
            let element = inactiveDays[i];
            element.classList.remove("error");
          }
        }
      }
    },
    reApplySavedDates(val) {
      setTimeout(() => {
        this.showSelectedSaveDates();
        if (this.savedDates.length == 0) {
          this.removeHighlightedDays();
        }
      }, 30);
    },
    excludedDates(val) {
      console.log("woop woop woop changes here");
      this.greyOutExcludedDates();
    },
  },
  async updated() {
    let self = this;
    let ourCalendar = document.querySelector(`#${self.idToUse}`);
    if (ourCalendar) {
      let days = ourCalendar.querySelector(".day");
      if (this.savedDates.length <= 0) {
        let selectedDays = ourCalendar.querySelectorAll(".selected");
        for (let i = 0; i < selectedDays.length; i++) {
          let element = selectedDays[i];
          if (element) {
            element.classList.remove("selected");
          }
        }
      }
      if (this.excludedDatesToUse) {
        if (this.excludedDatesToUse.length > 0) {
          this.greyOutExcludedDates();
        }
      }
    }
  },
  async mounted() {
    console.log(this.dates, "dates we got");
    if (this.excludedDatesToUse) {
      if (this.excludedDatesToUse.length > 0) {
        setTimeout(() => {
          this.greyOutExcludedDates();
        }, 500);
      }
    }
    if (this.savedDatesToUse.lengths > 0) {
      this.showSelectedSaveDates();
    }
    if (this.Bus) {
      this.Bus.$on("revert-to-first-date", (args) => {
        this.revertToFirstDay();
      });
    }
  },
};
</script>
