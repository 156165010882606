<template>
  <div class="itinerary">
    <error-list :errors="errors" />
    <confirm-modal
      ref="confirmModal"
      popUpMsg="If you have multiple dates then you must remove any additional timings you have."
      popUpTitleMsg="Are you sure you want to remove your additional timing?"
      v-on:confirm-method="cancelNewTimings"
      v-on:cancel-method="cancelNewDates"
    />
    <div class="row">
      <div class="date-selector-wrapper">
        <div class="date-selector-container">
          <calendar-set
            :eventStartDate="eventStartDate"
            :eventStartTime="eventStartTime"
            :eventEndDate="eventEndDate"
            :eventEndTime="eventEndTime"
            :savedDates="savedDates"
            :reApplySavedDates="editIndex"
            :excludedDates="excludedDatesToUse"
            :error="error('savedDates')"
            v-on:dateSelected="checkForTimings"
            :showTimes="true"
            :range="true"
            :checkForTimings="true"
            :Bus="methodBus"
          />
        </div>
      </div>

      <div class="desktop">
        <div class="custom-row-wrapper">
          <div class="itinerary-name-wrapper">
            <div class="col full">
              <diyobo-input
                type="text"
                label="Item Name"
                placeholder="Item Name"
                :required="true"
                v-model="name"
                :val="name"
                :error="error('name')"
                :tabIndex="'0'"
              />
            </div>
          </div>
          <div
            class="time-wrapper-container"
            v-for="(time, index) in timeArr"
            :key="index"
          >
            <div
              class="times-wrapper"
              :class="{ addition: index > 0, single: sortedDates.length > 1 }"
            >
              <div class="start">
                <diyobo-time-picker
                  :error="error(`startTime-${index}`)"
                  :required="true"
                  label="Start Time"
                  :val="time.startTime"
                  :timerWrapperId="`start-time-${index}`"
                  v-on:updateTime="setTime(`startTime-${index}`, ...arguments)"
                  inputClass="start-time"
                ></diyobo-time-picker>
              </div>
              <div class="end">
                <diyobo-time-picker
                  :error="error(`endTime-${index}`)"
                  :required="true"
                  label="End Time"
                  :val="time.endTime"
                  :timerWrapperId="`end-time-${index}`"
                  v-on:updateTime="setTime(`endTime-${index}`, ...arguments)"
                  inputClass="end-time"
                ></diyobo-time-picker>
              </div>
              <div class="time-options" v-if="sortedDates.length < 2">
                <span
                  class="remove-time"
                  v-if="index < timeArr.length - 1"
                  v-on:click="removeTiming(index)"
                >
                  <font-awesome-icon icon="times" />
                </span>
                <span class="add-time" v-if="index >= timeArr.length - 1">
                  <font-awesome-icon icon="plus-circle" v-on:click="addTime" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.itinerary {
  position: relative;
  padding: 25px 16px 0px;
  background-color: #1e1e1e;
  border: 1px solid #151515;
  border-radius: 4px;

  .add-time-wrapper {
    width: 100%;
    text-align: end;
    margin: 5px 0px;
    .add-time {
      height: 0%;
      width: 100%;
      text-align: end;
      cursor: pointer;
    }
  }

  .date-selector-wrapper {
    width: 100%;
    padding-left: 9px;
    .date-selector-container {
      width: 99.3%;
    }
  }

  label {
    width: calc(50% - 0.5em);
    font-size: 0.98em;
    & + .input-wrapper {
      margin-top: -2px;
    }
    &.error {
      color: #e60022;
    }
  }

  .mobile {
    display: none;
  }

  .desktop {
    padding: 8px;
    width: 100%;

    .custom-row-wrapper {
      display: flex;
      flex-flow: column;

      .itinerary-name-wrapper {
        .col.full {
          margin: 0 !important;
        }
      }
      .dates-wrapper,
      .times-wrapper {
        display: flex;
        margin-right: 15px;
        justify-content: space-between;
        .start {
          width: 47%;
        }
        .end {
          width: 47%;
        }
        .time-options {
          display: flex;

          .remove-time,
          .add-time {
            display: flex;
            justify-content: center;
            width: 20px;
            height: 20px;
            font-size: 20px;
            position: relative;
            top: 43px;
            cursor: pointer;
          }
        }
      }
      .times-wrapper.single {
        margin-right: 0px;
        .end {
          width: 50%;
        }
      }

      .time-wrapper-container {
        position: relative;
        .remove-timing {
          position: absolute;
          bottom: 14px;
          right: 12px;
          font-size: 21px;
          cursor: pointer;
          width: 20px;
          text-align: center;
        }
      }
      .addition {
        margin-top: 15px;
      }
    }
    // end of custom-row-wrapper

    .date {
      display: flex;
      position: relative;
      width: 100%;

      .placeholder {
        position: absolute;
        top: 12px;
        left: 14px;
        color: #878787;
      }
      // end placeholder

      .icon-calender {
        position: absolute;
        top: 12px;
        right: 14px;
        color: #878787;
      }
      // end icon-calender

      .edit-icon {
        height: 22px;
        width: 22px;
        margin-top: 4px;
      }

      input[type="date"],
      textarea {
        display: block;
        padding: 11px 0px;
        text-indent: 14px;
        width: 100%;
        font-family: inherit;
        font-size: 1em;
        color: #ffffff;
        background-color: #181818;
        border: none;
        border-bottom: 1px solid #3b3b3b;
        border-radius: 8px;
        box-shadow: inset 0 2px 7px 2px #000000b9;
        outline: none;
        -webkit-appearance: none;
        transition: 0.4s;
        margin-top: 7px;

        &:focus {
          padding-left: 16px;
          padding-right: 12px;
          background-color: #1f1f1f;
        }
      }
      input[type="date"]::-webkit-calendar-picker-indicator {
        opacity: 0.0000000001;
        position: relative;
        right: 2px;
        z-index: 777;
      }
      input[type="date"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        display: none;
      }
      input[type="date"]::-webkit-clear-button {
        display: none;
      }
    }
    // end date
  }

  .times-icon {
    font-size: 20px;
    position: absolute;
    top: 5px;
    left: 790px;
    cursor: pointer;
  }
}

@media screen and (max-width: 675px) {
  .itinerary {
    .mobile {
      display: inline;
      padding: 8px;

      .date {
        display: flex;
        position: relative;
        width: 100%;

        .placeholder {
          position: absolute;
          top: 12px;
          left: 14px;
          color: #878787;
        }
        // end placeholder

        .icon-calender {
          position: absolute;
          top: 12px;
          right: 14px;
          color: #878787;
        }
        // end icon-calender

        .edit-icon {
          height: 22px;
          width: 22px;
          margin-top: 4px;
        }

        input[type="date"],
        textarea {
          display: block;
          padding: 11px 0px;
          text-indent: 14px;
          width: 100%;
          font-family: inherit;
          font-size: 1em;
          color: #ffffff;
          background-color: #181818;
          border: none;
          border-bottom: 1px solid #3b3b3b;
          border-radius: 8px;
          box-shadow: inset 0 2px 7px 2px #000000b9;
          outline: none;
          -webkit-appearance: none;
          transition: 0.4s;
          margin-top: 7px;

          &:focus {
            padding-left: 16px;
            padding-right: 12px;
            background-color: #1f1f1f;
          }
        }
        input[type="date"]::-webkit-calendar-picker-indicator {
          opacity: 0.0000000001;
          position: relative;
          right: 2px;
          z-index: 777;
        }
        input[type="date"]::-webkit-inner-spin-button {
          -webkit-appearance: none;
          display: none;
        }
        input[type="date"]::-webkit-clear-button {
          display: none;
        }
      }
      // end date
    }

    .desktop {
      display: none;
    }
  }
}
</style>

<script>
import DiyoboInput from "@/components/DiyoboInput";
import ErrorList from "../components/ErrorList";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDown,
  faCalendarAlt,
  faClock,
  faTimes,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import dateFormat from "dateformat";
import DiyoboCalendar from "../components/diyoboCalendar.vue";
import DiyoboTimePicker from "../components/diyoboTimePicker.vue";
import CalendarSet from "./CalendarSet.vue";
import ConfirmModal from "./modals/ConfirmModal.vue";
import Vue from "vue";

library.add(faAngleDown);
library.add(faCalendarAlt);
library.add(faClock);
library.add(faTimes);
library.add(faPlusCircle);

export default {
  name: "itinerary",
  components: {
    DiyoboInput,
    ErrorList,
    FontAwesomeIcon,
    DiyoboCalendar,
    DiyoboTimePicker,
    CalendarSet,
    ConfirmModal,
  },
  data() {
    return {
      name: this.info.name,
      errors: [
        { name: "name", display: "Item Name", error: false },
        {
          name: "savedDates",
          display: "Please Select Event Dates",
          error: false,
        },
        { name: "startTime-0", display: "Start Time", error: false },
        { name: "endTime-0", display: "End Time", error: false },
      ],
      timeArr: [
        {
          startTime: "",
          endTime: "",
        },
      ],
      savedDates: [],
      editIndex: 0,
      excludedDatesToUse: this.excludedDates,
      methodBus: new Vue(),
    };
  },
  props: {
    info: Object,
    bus: Object,
    id: Number,
    index: Number,
    type: String,
    eventStartDate: String,
    eventStartTime: String,
    eventEndDate: String,
    eventEndTime: String,
    excludedDates: Array,
  },
  methods: {
    error(input) {
      let error = this.errors.find((e) => e.name == input);
      if (input == "savedDates") {
        console.log("error found");
      }
      return error && error.error;
    },
    updateStartDate(date) {
      this.startDate = date;
    },
    updateEndDate(date) {
      this.endDate = date;
    },
    validate() {
      let self = this;
      let startEndPair = false;
      let startIndex;

      this.errors.forEach((e) => {
        e.errors = [];

        if (e.name == "savedDates") {
          if (this[e.name].length > 0) {
            e.error = false;
          } else {
            e.error = true;
          }
        } else if (e.name.includes("startTime")) {
          let index = e.name.split("-")[1];
          let key = e.name.split("-")[0];
          if (self.timeArr[index][key]) {
            let firstSelectedDate = this.sortedDates[0];
            if (firstSelectedDate) {
              let dateObj = new Date(
                firstSelectedDate.month +
                  "-" +
                  firstSelectedDate.day +
                  "-" +
                  firstSelectedDate.year +
                  " " +
                  self.timeArr[index][key]
              );
              console.log(dateObj);
              let comparedStartDate = new Date(
                self.eventStartDate + " " + self.eventStartTime
              );
              if (
                dateObj.getTime() < comparedStartDate.getTime() &&
                comparedStartDate.getTime() != dateObj.getTime()
              ) {
                e.error = true;
                startEndPair = true;
                startIndex = index;
                e.errors.push(
                  `${e.display} must be within your event schedule`
                );
              } else {
                if (this.timeArr[index][key].split("").length == 8) {
                  e.error = false;
                } else {
                  e.error = true;
                  e.errors.push(`${e.display} must be selected`);
                }
              }
            } else {
              if (this.timeArr[index][key].split("").length == 8) {
                e.error = false;
              } else {
                e.error = true;
                e.errors.push(`${e.display} must be selected`);
              }
            }
          } else {
            e.error = true;
            e.errors.push(`${e.display} must be selected`);
          }
        } else if (e.name.includes("endTime")) {
          let index = e.name.split("-")[1];
          let key = e.name.split("-")[0];
          if (self.timeArr[index][key]) {
            let firstSelectedDate = this.sortedDates[0];
            let lastSelectedDate =
              this.sortedDates[this.sortedDates.length - 1];
            if (lastSelectedDate) {
              let dateObj = new Date(
                lastSelectedDate.month +
                  "-" +
                  lastSelectedDate.day +
                  "-" +
                  lastSelectedDate.year +
                  " " +
                  self.timeArr[index][key]
              );
              let comparedEndDate = new Date(
                self.eventEndDate + " " + self.eventEndTime
              );
              let startDateCheck = new Date(
                firstSelectedDate.month +
                  "-" +
                  firstSelectedDate.day +
                  "-" +
                  firstSelectedDate.year +
                  " " +
                  self.timeArr[index]["startTime"]
              );
              console.log(
                dateObj,
                "wtf man",
                lastSelectedDate,
                "Holy moly foly",
                index,
                key
              );
              if (dateObj > comparedEndDate) {
                if (!startEndPair) {
                  e.error = true;
                  e.errors.push(
                    `${e.display} must be within your event schedule`
                  );
                } else {
                  e.error = false;
                  this.errors[startIndex].error = false;
                  startEndPair = false;
                }
              } else if (startDateCheck >= dateObj) {
                if (!startEndPair) {
                  e.error = true;
                  e.errors.push(`${e.display} must be after the start time`);
                } else {
                  e.error = false;
                  this.errors[parseInt(startIndex * 2) + 2].error = false;
                  startEndPair = false;
                }
              } else {
                if (this.timeArr[index][key].split("").length == 8) {
                  e.error = false;
                } else {
                  e.error = true;
                  e.errors.push(`${e.display} must be selected`);
                }
              }
            } else {
              if (this.timeArr[index][key].split("").length == 8) {
                e.error = false;
              } else {
                e.error = true;
                e.errors.push(`${e.display} must be selected`);
              }
            }
          } else {
            e.error = true;
            e.errors.push(`${e.display} must be selected`);
          }
        } else {
          e.error = !this[e.name];
        }

        if (e.error) {
          if (e.name == "savedDates") {
            e.errors.push(`${e.display}`);
          } else if (e.name.includes("endTime")) {
          } else if (e.name.includes("startTime")) {
          } else {
            e.errors.push(`${e.display} is required`);
          }
        }
      });
      return !this.errors.some((e) => e.error);
    },
    setTime(varToUpdate, time) {
      let index = varToUpdate.split("-")[1];
      let key = varToUpdate.split("-")[0];
      this.timeArr[index][key] = time;
    },
    addTime() {
      if (this.timeValidation()) {
        let TimeObj = {
          startTime: "",
          endTime: "",
        };
        let length = this.timeArr.length;
        let errorArr = [
          { name: `startTime-${length}`, display: "Start Time", error: false },
          { name: `endTime-${length}`, display: "End Time", error: false },
        ];
        this.errors.push(...errorArr);
        this.timeArr.push(TimeObj);
      }
    },
    timeValidation() {
      let self = this;
      let noErrors = true;
      let dateToCreate = new Date();
      let dateToUse = dateFormat(dateToCreate, "yyyy-mm-dd").split("-");
      dateToUse = {
        day: dateToUse[2],
        year: dateToUse[0],
        month: dateToUse[1],
      };
      this.errors.forEach((e) => {
        if (e.name.includes("endTime")) {
          let index = e.name.split("-")[1];
          let key = e.name.split("-")[0];
          e.error = !this.timeArr[index][key];
          let dateObj = new Date(
            dateToUse.month +
              "/" +
              dateToUse.day +
              "/" +
              dateToUse.year +
              " " +
              self.timeArr[index][key]
          );
          let startDateObj = new Date(
            dateToUse.month +
              "/" +
              dateToUse.day +
              "/" +
              dateToUse.year +
              " " +
              self.timeArr[index]["startTime"]
          );
          if (!this.timeArr[index][key]) {
            noErrors = false;
            e.error = true;
            e.errors = [];
            e.errors.push(`${e.display} must be a valid time`);
          } else if (startDateObj > dateObj) {
            noErrors = false;
            e.error = true;
            e.errors = [];
            e.errors.push(`${e.display} must be after the start Time`);
          } else {
            e.error = false;
            e.errors = [];
          }
        } else if (e.name.includes("startTime")) {
          let index = e.name.split("-")[1];
          let key = e.name.split("-")[0];
          e.error = !this.timeArr[index][key];
          let dateObj = new Date(
            dateToUse.month +
              "/" +
              dateToUse.day +
              "/" +
              dateToUse.year +
              " " +
              self.timeArr[index][key]
          );
          let endDateObj = new Date(
            dateToUse.month +
              "/" +
              dateToUse.day +
              "/" +
              dateToUse.year +
              " " +
              self.timeArr[index]["endTime"]
          );
          if (!this.timeArr[index][key]) {
            noErrors = false;
            e.error = true;
            e.errors = [];
            e.errors.push(`${e.display} must be a valid time`);
          } else if (endDateObj < dateObj) {
            noErrors = false;
            e.error = true;
            e.errors = [];
            e.errors.push(`${e.display} must be after the end Time`);
          } else {
            e.error = false;
            e.errors = [];
          }
        } else if (e.name.includes("savedDates")) {
          if (self[e.name].length > 0) {
            e.error = false;
            e.errors = [];
          } else {
            noErrors = false;
            e.error = true;
            e.errors = [];
            e.errors.push(`${e.display}`);
          }
        }
      });
      return noErrors;
    },
    anyDataCheck() {
      let self = this;
      if (this.index > 0) {
        let anyData = false;
        this.errors.forEach((e) => {
          if (e.name == "name") {
            if (self[e.name]) {
              anyData = true;
            }
          } else if (
            e.name.includes("startTime") ||
            e.name.includes("endTime")
          ) {
            let index = e.name.split("-")[1];
            let key = e.name.split("-")[0];
            if (self.timeArr[index][key]) {
              anyData = true;
            }
          } else if (e.name == "savedDates") {
            if (self[e.name].length > 0) {
              anyData = true;
            }
          }
        });
        if (!anyData) return true;
      }
    },
    createItinerary() {
      let self = this;
      let dateArray = [];
      if (this.sortedDates.length > 1) {
        let firstDate = this.sortedDates[0];
        let lastDate = this.sortedDates[this.sortedDates.length - 1];
        let timeObj = this.sortedTimes[0];
        let startDate = new Date(
          firstDate.month +
            "-" +
            firstDate.day +
            "-" +
            firstDate.year +
            " " +
            timeObj.startTime
        );
        let endDate = new Date(
          lastDate.month +
            "-" +
            lastDate.day +
            "-" +
            lastDate.year +
            " " +
            timeObj.endTime
        );
        let dateObj = {
          startDate: dateFormat(startDate, "yyyy/mm/dd hh:MM TT"),
          endDate: dateFormat(endDate, "yyyy/mm/dd hh:MM TT"),
        };
        dateArray.push(dateObj);
      } else if (this.sortedDates.length == 1) {
        let onlyDate = this.sortedDates[0];
        this.sortedTimes.forEach((time) => {
          let startDate = new Date(
            onlyDate.month +
              "-" +
              onlyDate.day +
              "-" +
              onlyDate.year +
              " " +
              time.startTime
          );
          let endDate = new Date(
            onlyDate.month +
              "-" +
              onlyDate.day +
              "-" +
              onlyDate.year +
              " " +
              time.endTime
          );
          let dateObj = {
            startDate: dateFormat(startDate, "yyyy/mm/dd hh:MM TT"),
            endDate: dateFormat(endDate, "yyyy/mm/dd hh:MM TT"),
          };
          dateArray.push(dateObj);
        });
      }

      console.log(dateArray, "array we are creating");
      let idToUse = this.indexToUse;
      let itinerary = {
        id: idToUse,
        name: self.name,
        dateArray: dateArray,
      };
      return itinerary;
    },
    removeTiming(indexToRemove) {
      let startingErrorName = `startTime-${indexToRemove}`;
      let endingErrorName = `endTime-${indexToRemove}`;
      let arrayToReplace = this.timeArr.filter((time, index) => {
        if (indexToRemove != index) {
          return time;
        }
      });
      let index = 0;
      let errorArrayToReplace = this.errors.filter((error) => {
        if (error.name != startingErrorName && error.name != endingErrorName) {
          if (error.name.includes("startTime-")) {
            let temp = error.name.split("-");
            error.name = temp[0] + "-" + index;
            return error;
          } else if (error.name.includes("endTime-")) {
            let temp = error.name.split("-");
            error.name = temp[0] + "-" + index;
            index = index + 1;
            return error;
          } else {
            return error;
          }
        }
      });

      this.timeArr.splice(0, this.timeArr.length, ...arrayToReplace);
      this.errors.splice(0, this.errors.length, ...errorArrayToReplace);
    },
    fillEditData() {
      if (this.info.dateArray) {
        this.savedDates = [];
        this.timeArr = [];
        this.info.dateArray.forEach((date, index) => {
          if (typeof date.startDate != "object") {
            let splitedStartDateObj = date.startDate.split(" ");
            let splitedEndDateObj = date.endDate.split(" ");
            let dateToUse = new Date(splitedStartDateObj[0]);
            let splittedStartDateObj = dateFormat(
              date.startDate,
              "mmmm-d-yyyy"
            ).split("-");
            let splittedEndDateObj = dateFormat(
              date.endDate,
              "mmmm-d-yyyy"
            ).split("-");
            let startDateObj = {
              day: splittedStartDateObj[1],
              month: splittedStartDateObj[0],
              year: splittedStartDateObj[2],
            };
            let endDateObj = {
              day: splittedEndDateObj[1],
              month: splittedEndDateObj[0],
              year: splittedEndDateObj[2],
            };
            let sameDate = false;
            if (
              startDateObj.year === endDateObj.year &&
              startDateObj.month === endDateObj.month &&
              +startDateObj.day === +endDateObj.day
            ) {
              sameDate = true;
            }
            if (sameDate) {
              let sameDataCheck = this.savedDates.some(
                (date) =>
                  date.year === endDateObj.year &&
                  date.month === endDateObj.month &&
                  +date.day === +endDateObj.day
              );
              if (!sameDataCheck) {
                this.savedDates.push(endDateObj);
              }
            } else {
              let startDateCheck = this.savedDates.some(
                (date) =>
                  startDateObj.year === endDateObj.year &&
                  startDateObj.month === endDateObj.month &&
                  +startDateObj.day === +endDateObj.day
              );
              if (!startDateCheck) {
                this.savedDates.push(endDateObj);
              }
              let endDateCheck = this.savedDates.some(
                (date) =>
                  startDateObj.year === date.year &&
                  startDateObj.month === date.month &&
                  +startDateObj.day === +date.day
              );
              if (endDateCheck) {
                this.savedDates.push(endDateObj);
              }
            }
            // this.savedDates.push(startDateObj);
            // let sameDataCheck = this.savedDates.some(date => startDateObj.year === endDateObj.year && startDateObj.month === endDateObj.month && +startDateObj.day === +endDateObj.day);
            // if(!sameDataCheck) {
            //   this.savedDates.push(endDateObj);
            // }
            let dateDiff = +endDateObj.day - +startDateObj.day;
            for (let i = 1; i <= dateDiff; i++) {
              let dayToUse = +startDateObj.day + i;
              let newDateObj = {
                day: `${dayToUse}`,
                month: splittedStartDateObj[0],
                year: splittedStartDateObj[2],
              };
              this.savedDates.push(newDateObj);
            }
            let timeObj = {
              startTime: splitedStartDateObj[1] + " " + splitedStartDateObj[2],
              endTime: splitedEndDateObj[1] + " " + splitedEndDateObj[2],
            };
            let anyTimeData = this.timeArr.some(
              (time) =>
                time.startTime == timeObj.startTime &&
                time.endTime == timeObj.endTime
            );
            if (!anyTimeData) {
              this.timeArr.push(timeObj);
              if (index > 0) {
                let errorsToInject = [
                  {
                    name: `startTime-${index}`,
                    display: "Start Time",
                    error: false,
                  },
                  {
                    name: `endTime-${index}`,
                    display: "End TIme",
                    error: false,
                  },
                ];
                this.errors.push(...errorsToInject);
              }
            }
            //this.timeArr.push(timeObj);
          } else {
            let startDate = new Date(+date.startDate.$date.$numberLong);
            let startTime = dateFormat(startDate, "hh:MM TT");
            let splittedStartDateObj = dateFormat(
              startDate,
              "mmmm-d-yyyy"
            ).split("-");
            let startDateObj = {
              day: splittedStartDateObj[1],
              month: splittedStartDateObj[0],
              year: splittedStartDateObj[2],
            };
            let endDate = new Date(+date.endDate.$date.$numberLong);
            let endTime = dateFormat(endDate, "hh:MM TT");
            let splittedEndDateObj = dateFormat(endDate, "mmmm-d-yyyy").split(
              "-"
            );
            let EnddateObj = {
              day: splittedEndDateObj[1],
              month: splittedEndDateObj[0],
              year: splittedEndDateObj[2],
            };
            let timeObj = {
              startTime: startTime,
              endTime: endTime,
            };
            this.savedDates.push(startDateObj, EnddateObj);
            this.timeArr.push(timeObj);
          }
        });
        this.editIndex += 1;
        this.name = this.info.name;
      }
    },
    checkForTimings() {
      console.log("should see me first");
      if (this.timeArr.length > 1) {
        this.$refs.confirmModal.open(this.test);
      }
    },
    cancelNewDates() {
      this.methodBus.$emit("revert-to-first-date");
    },
    cancelNewTimings() {
      let timeSaved = this.timeArr[0];
      let errorsToReplace = this.errors.filter((error) => {
        if (
          error.name.includes("endTime") ||
          error.name.includes("startTime")
        ) {
          let index = error.name.split("-")[1];
          if (+index == 0) {
            return error;
          }
        } else {
          return error;
        }
      });
      this.timeArr.splice(0, this.timeArr.length, timeSaved);
      this.errors.splice(0, this.errors.length, ...errorsToReplace);
    },
  },
  mounted() {
    let self = this;

    if (this.type == "edit") {
      this.fillEditData();
    }

    this.bus.$on("save-itinerary-data", (args) => {
      if (!this.validate()) {
        console.log("error");
        this.$emit("errored", true);
      } else {
        let itinerary = this.createItinerary();
        this.$emit("data", itinerary.id, itinerary);
      }
    });
    this.bus.$on("itinerary-error-check", (args) => {
      if (this.anyDataCheck()) {
        this.$emit("errored", true);
      } else {
        this.$emit("errored", false);
      }
    });
  },
  beforeDestroy() {
    if (this.bus) {
      this.bus.$off("save-itinerary-data");
    }
  },
  computed: {
    sortedDates() {
      let self = this;
      let arrayToMutate = [...this.savedDates];
      return arrayToMutate.sort((a, b) => {
        let firstDate = new Date(a.month + "-" + a.day + "-" + a.year);
        let secondDate = new Date(b.month + "-" + b.day + "-" + b.year);
        if (firstDate > secondDate) {
          return 1;
        } else {
          return -1;
        }
      });
    },
    sortedTimes() {
      let self = this;
      let removingEmptyObjs = this.timeArr.filter((ele) => {
        if (ele.startTime && ele.endTime) {
          return ele;
        }
      });
      let arrayToMutate = [...removingEmptyObjs];
      return arrayToMutate.sort((a, b) => {
        let dateStringToUse = dateFormat(new Date(), "yyyy-mm-dd");
        let firstStartDate = new Date(dateStringToUse + " " + a.startTime);
        let firstEndDate = new Date(dateStringToUse + " " + a.endTime);
        let secondStartDate = new Date(dateStringToUse + " " + b.startTime);
        let secondEndDate = new Date(dateStringToUse + " " + b.endTime);
        if (b.endTime && b.startTime && a.startTime && b.endTime) {
          if (firstStartDate <= secondStartDate) {
            if (firstEndDate > secondEndDate) {
              return 1;
            } else {
              return -1;
            }
          } else {
            return 0;
          }
        } else {
          return -2;
        }
      });
    },
    indexToUse() {
      if (this.id > -1) {
        return this.id;
      } else {
        return this.index;
      }
    },
  },
  watch: {
    info(val) {
      if (val.dateArray && val.dateArray.length > 0) {
        this.fillEditData();
      }
    },
  },
};
</script>
