<template>
  <div class="event-calendar-wrapper" :data-id="UniqueIdToUse">
    <range-calendar
      :dates="displayedData"
      :savedDates="savedDates"
      v-on:dates="handleDates"
      :error="error"
      :excludedDates="excludedDates"
      :reApplySavedDates="reApplySavedDates"
      :eventStartTime="eventStartTime"
      :eventEndTime="eventEndTime"
      :showTimes="showTimes"
      :changeColour="changeColour"
      :checkForTimings="true"
      :Bus="Bus"
      :UniqueId="UniqueIdToUse"
      v-on:dateSelected="$emit('dateSelected')"
      v-if="range && UniqueIdToUse"
    />
    <toggle-calendar
      :dates="displayedData"
      :savedDates="savedDates"
      v-on:dates="handleDates"
      :error="error"
      :excludedDates="excludedDates"
      :reApplySavedDates="reApplySavedDates"
      :eventStartTime="eventStartTime"
      :eventEndTime="eventEndTime"
      :showTimes="showTimes"
      :changeColour="changeColour"
      :UniqueId="UniqueIdToUse"
      :parentBus="Bus"
      v-if="toggle && UniqueIdToUse"
    />
    <div class="calendar-button-wrapper btn-wrapper">
      <button
        class="calendar-btn btn"
        v-for="(month, index) in monthsToDisplay"
        :class="{ active: index == 0 }"
        :key="month"
        v-on:click="selectDate($event, month)"
      >
        {{ month }}
      </button>
    </div>
  </div>
</template>

<style lang="less" scoped>
.event-calendar-wrapper {
  width: 100%;
  //padding-left: 5px;
  // padding-right: 15px;

  .btn {
    margin: 0.25em;
    padding: 8px 18px;
    position: relative;
    z-index: 1;
    border: 1px solid;
    border-radius: 5px;
    font-size: 1em;
    font-weight: 500;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    background-color: #4a639e;
    background: linear-gradient(#40578d, #2f4265);
    color: #fff;
    border-color: #4a639e;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: linear-gradient(#40578d, #2f4265);
      opacity: 0;
      transition: 0.4s;
    }

    // &:hover::before {
    //     opacity: 1;
    // }
  }
  .btn.active {
    background-color: #4eb95e;
    background: linear-gradient(#4cac55, #39793f);
    border-color: #4eb95e;
  }
  .btn-wrapper {
    //display: flex;
    //justify-content: space-between;
  }
}
</style>

<script>
import RangeCalendar from "./RangeEventCalendar.vue";
import ToggleCalendar from "./ToggleEventCalendar.vue";
import dateFormat from "dateformat";

export default {
  name: "calendar-set",
  components: {
    RangeCalendar,
    ToggleCalendar,
  },
  props: {
    eventStartDate: String,
    eventStartTime: String,
    eventEndDate: String,
    eventEndTime: String,
    savedDates: Array,
    excludedDates: Array,
    error: Boolean,
    reApplySavedDates: Number,
    showTimes: Boolean,
    changeColour: String,
    range: Boolean,
    toggle: Boolean,
    checkForTimings: Boolean,
    Bus: Object,
  },
  data() {
    return {
      datesToCheck: [],
      monthsToDisplay: [],
      displayedData: [],
      calendarData: [],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      UniqueIdToUse: "",
    };
  },
  methods: {
    handleDates(dates) {
      this.$emit("dates", dates);
    },
    selectDate(e, month) {
      let elementsToLoopThrough = document.querySelectorAll(".active");
      console.log(elementsToLoopThrough);
      for (let i = 0; i < elementsToLoopThrough.length; i++) {
        let element = elementsToLoopThrough[i];
        if (element.classList.contains("active")) {
          element.classList.remove("active");
        }
      }
      let dataToInject = this.calendarData.find((date) => date.month == month);
      if (dataToInject) {
        this.displayedData.shift();
        this.displayedData.push(dataToInject);
        e.target.classList.add("active");
      }
    },
    buildDates() {
      this.resetData();
      let self = this;

      let eventDifferenceInTime =
        new Date(self.eventEndDate).getTime() -
        new Date(self.eventStartDate).getTime();
      let eventDifferenceInDays = eventDifferenceInTime / (1000 * 3600 * 24);
      console.log(
        eventDifferenceInDays,
        "this is the value being rounded",
        Math.floor(eventDifferenceInDays),
        "This is the hooligan causing problem"
      );
      let eventStartDate = dateFormat(self.eventStartDate, "mmmm-dd-yyyy");
      let eventEndDate = dateFormat(self.eventEndDate, "mmmm-dd-yyyy");
      for (let i = 0; i <= Math.floor(eventDifferenceInDays); i++) {
        let dateObj = {
          daysToCheck: [eventStartDate.split("-")[1]],
        };
        dateObj.day = eventStartDate.split("-")[1];
        dateObj.month = eventStartDate.split("-")[0];
        dateObj.year = eventStartDate.split("-")[2];
        let dateToIncreatment = new Date(eventStartDate);
        dateToIncreatment.setDate(dateToIncreatment.getDate() + 1);
        eventStartDate = dateFormat(dateToIncreatment, "mmmm-dd-yyyy");
        this.datesToCheck.push(dateObj);
      }

      this.datesToCheck.forEach((date) => {
        let exists = false;
        for (let x = 0; x < self.calendarData.length; x++) {
          if (date.month == self.calendarData[x].month) {
            //self.calendarData[x].days.push(date.day);
            exists = true;
          }
        }

        if (!exists) {
          let dateObj = {};
          dateObj.month = date.month;
          self.monthsToDisplay.push(date.month);
          dateObj.days = [date.day];
          dateObj.daysToCheck = date.daysToCheck;
          dateObj.year = date.year;
          self.calendarData.push(dateObj);
          //change everything back to display data if it fails
          // this.displayedData.push(dateObj);
        } else {
          self.calendarData = self.calendarData.map((ele) => {
            if (ele.month == date.month) {
              ele.daysToCheck.push(...date.daysToCheck);
              ele.days.push(date.day);
            }
            return ele;
          });
        }
      });

      for (let x = 0; x < this.calendarData.length; x++) {
        this.calendarData[x].weeks = [];
        let month = this.calendarData[x].month;
        let date = new Date(
          x +
            1 +
            " " +
            this.calendarData[x].month +
            " " +
            this.calendarData[x].year
        );
        let days = [];

        while (this.months[date.getMonth()] === month) {
          days.push(dateFormat(new Date(date), "ddd mmmm dd, yyyy"));
          date.setDate(date.getDate() + 1);
        }

        let startIndex = days[0].split(" ")[0];
        if (startIndex)
          switch (startIndex) {
            case "Sun":
              startIndex = 0;
              break;
            case "Mon":
              startIndex = 1;
              break;
            case "Tue":
              startIndex = 2;
              break;
            case "Wed":
              startIndex = 3;
              break;
            case "Thu":
              startIndex = 4;
              break;
            case "Fri":
              startIndex = 5;
              break;
            case "Sat":
              startIndex = 6;
          }

        let counter = 0;
        let didIStart = false;
        for (let d = 0; d < 6; d++) {
          let week = [];

          for (let y = 0; y < 7; y++) {
            if (counter >= days.length) {
              break;
            }

            if (startIndex == y && !didIStart) {
              week.push(dateFormat(new Date(days[counter]), "d"));
              counter++;
              didIStart = true;
            } else {
              if (didIStart) {
                week.push(dateFormat(new Date(days[counter]), "d"));
                counter++;
              } else week.push("");
            }
          }
          if (week.length > 0) {
            this.calendarData[x].weeks.push(week);
          }
          // }
        }
      }
      if (this.calendarData.length > 0) {
        this.displayedData.push(this.calendarData[0]);
      }
    },
    resetData() {
      this.datesToCheck = [];
      this.monthsToDisplay = [];
      this.displayedData = [];
      this.calendarData = [];
    },
    generateUniqueIds() {
      let allTheCalendars = document.querySelectorAll(
        ".event-calendar-wrapper"
      );
      let uniqueId = 0;
      for (let i = 0; i < allTheCalendars.length; i++) {
        let calendar = allTheCalendars[i];
        if (calendar) {
          if (calendar.dataset.id) {
            uniqueId++;
          } else {
            i += allTheCalendars.length;
          }
        }
      }
      this.UniqueIdToUse = `calendar-${uniqueId}`;
    },
  },
  watch: {
    eventStartDate(val) {
      if (val) {
        this.buildDates();
      }
    },
    eventEndDate(val) {
      if (val) {
        this.buildDates();
      }
    },
  },
  async mounted() {
    this.buildDates();
    if (!this.UniqueIdToUse) {
      setTimeout(() => {
        this.generateUniqueIds();
      }, 50);
    }
  },
};
</script>
