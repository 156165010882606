<template>
  <div class="event-date">
    <div class="date-picker-wrapper">
      <datepicker
        v-model="start.date"
        input-class="dateStart"
        :id="pickerId"
        name="date"
        @selected="updateInput"
        :disabled-dates="disabledDates"
      ></datepicker>
    </div>
    <diyobo-input
      type="newDate"
      :label="label"
      :id="inputId"
      :placeholder="placeholder"
      :required="true"
      v-model="date"
      autocomplete="off"
      :error="error"
      :val="date"
      :max="10"
      v-on:blur="callCalendar(pickerId)"
      v-on:callCalendar="callCalendar(pickerId)"
      :tabIndex="tabIndex"
    />
  </div>
</template>

<style lang="less" scoped>
.event-date {
  position: relative;

  .date-picker-wrapper {
    color: #000000;
    position: absolute;
    top: 79px;
  }
}
// end event-date
</style>

<script>
import DiyoboInput from "@/components/DiyoboInput.vue";
import Datepicker from "vuejs-datepicker";
import dateFormat from "dateformat";

export default {
  name: "date",
  components: {
    DiyoboInput,
    Datepicker
  },
  data() {
    return {
      // placeholder: dateFormat(new Date(), "dddd mmmm d, yyyy"),
      date: this.val,
      // start: {
      //   date: new Date()
      // },
      disabledDates: {
        to: new Date(dateFormat(new Date(), "yyyy/mm/dd"))
        //if it fails do this new Date(dateFormat(new Date().setDate(new Date().getDate()), 'yyyy/mm/dd'))
      }
    };
  },
  props: {
    label: String,
    pickerId: String,
    inputId: String,
    error: Boolean | String,
    minDate: String,
    maxDate: String,
    val: String,
    tabIndex: String,
    excludedDates: Array,
    placeholderDate: String,
    startDateToUse: String,
    minSelectableDate: String
  },
  watch: {
    // minDate: function(val){
    //   let split = val.split('-')
    //   let date = split[2] + '-' + split[1] + '-' + split[0];
    //   this.disabledDates.to = new Date(date)
    // },
    val: function(val) {
      // let date = val.split(',')
      // if(date.length > 1){
      //   this.date = dateFormat(val, "dd-mm-yyyy");
      // } else {
      //   this.date = val;
      // }
      console.log(JSON.stringify(val), "I doubt I could be the problem");
      this.date = val;
    },
    minDate(val) {
      let dateCheck = new Date(val);
      if (val != "") {
        //  let split = this.minDate.split('-');
        //  let date = split[2] + '-' + split[1] + '-' + split[0];
        console.log(
          JSON.stringify(val),
          "last place I could be this problematic "
        );
        this.disabledDates.to = new Date(val);
        this.start.date = new Date(val);
      }
    },
    maxDate(val) {
      let dateCheck = new Date(val);
      if (dateCheck) {
        this.disabledDates.from = new Date(val);
      }
    }
  },
  methods: {
    updateInput(val) {
      let date = dateFormat(Date.parse(val), "dddd mmmm d, yyyy");
      this.date = date;
      this.$emit("updateDate", date);
      document.getElementById(this.inputId).blur();
    },
    callCalendar(id) {
      // debugger;
      document.getElementById(id).click();
    }
  },
  computed: {
    excludedDatesToUse() {
      return this.excludedDates;
    },
    placeholder: {
      get() {
        if (this.placeholderDate) {
          return dateFormat(
            new Date(this.placeholderDate),
            "dddd mmmm d, yyyy"
          );
        } else {
          return dateFormat(new Date(), "dddd mmmm d, yyyy");
        }
      },
      set(val) {
        //we'll get to this sometime
      }
    },
    start: {
      get() {
        if (this.startDateToUse) {
          return {
            date: new Date(this.startDateToUse)
          };
        } else if (this.val) {
          return {
            date: new Date(this.val)
          };
        } else {
          // return no start date so the blue square doesn't cause confusion
          // to those with weaker minds
          return {};
        }
      },
      set(val) {
        //well do something special soon
      }
    }
  },
  mounted() {
    document.getElementById(this.pickerId).style.display = "none";
    console.log(
      JSON.stringify(this.disabledDates),
      "You thought this was? Get real mate"
    );
    if (this.minDate) {
      if (this.minDate.includes("-")) {
        let split = this.minDate.split("-");
        let date = split[2] + "-" + split[1] + "-" + split[0];
        this.disabledDates.to = new Date(date);
        this.start.date = new Date(date);
      } else {
        this.disabledDates.to = new Date(`${this.minDate}`);
        this.start.date = new Date(`${this.minDate}`);
      }
    }
    if (this.maxDate) {
      let doYouHaveAColon = this.maxDate.includes(":");
      if (doYouHaveAColon) {
        let dateToInject = this.maxDate.split(":");
        if (dateToInject[1].length == 8) {
          this.disabledDates.from = new Date(dateToInject.join(" "));
        } else {
          this.disabledDates.from = new Date(`${this.maxDate}`);
        }
      } else {
        this.disabledDates.from = new Date(`${this.maxDate}`);
      }

      // maybe this fixes the shitty off by 1 errors
      this.disabledDates.from.setUTCHours(23);
    }
    if (this.minSelectableDate) {
      this.disabledDates.to = new Date(this.minSelectableDate);
    }
  }
};
</script>
