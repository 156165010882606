<template>
  <div class="itinerary-set">
    <div id="itinerary-cont">
      <h3 class="itinerary-title-label">
        Itinerary/Set List
        <b>*</b>
      </h3>
      <p class="itinerary-title-msg b2">
        Please select the date you would like to add an itinerary item to and
        then fill out the info below.
      </p>
      <div
        class="itinerary-wrapper"
        v-for="itinerary in itineraryToInject"
        :key="itinerary.id"
      >
        <itinerary
          :info="itinerary"
          :bus="bus"
          :id="itinerary.id"
          :index="createdIndex"
          :key="createdIndex"
          :type="itineraryType"
          :eventStartDate="eventStartDate"
          :eventStartTime="eventStartTime"
          :eventEndDate="eventEndDate"
          :eventEndTime="eventEndTime"
          :excludedDates="excludedDates"
          v-on:data="inputDataUpload('itineraries', ...arguments)"
          v-on:errored="itineraryErrored"
          v-on:removeItinerary="removeItinerary(itinerary.id)"
          v-on:save-itinerary-info="saveItineraryInfo"
        />
      </div>
      <div class="add-itinerary-wrapper">
        <button class="add-itinerary" v-on:click="addItinerary">
          <!-- <font-awesome-icon class="icon-size" icon="plus" :itineraryUpdateMsg='itineraryUpdateMsg' /> -->
          {{ itineraryUpdateMsg }}
        </button>
      </div>
    </div>
    <div class="divider" v-if="existingItineraries.length > 0"></div>
    <div class="existing-itineraries">
      <h3 v-if="displayItineriares.length > 0">Ordered Itinerary/Set List</h3>
      <AddedItinerary
        :itineraries="displayItineriares"
        v-on:removeItinerary="removeItinerary"
        v-on:click="updateItinerary"
      />
    </div>
  </div>
</template>

<style lang="less" scoped>
div.itinerary-set {
  margin-top: 1.5em;
  .add-itinerary-wrapper {
    margin-top: 1.5em;
    width: 100%;
    button.add-itinerary {
      cursor: pointer;
      position: relative;
      padding: 2px 16px;
      width: 100%;
      height: 50px;
      text-transform: uppercase;
      //color: #828282;
      //background-color: #1e1e1e;
      border: 1px solid; // #151515;
      border-radius: 4px;
      outline: none;
      -webkit-appearance: none;
      background-color: #4cac55;
      background: linear-gradient(#4cac55, #39793f);
      color: #fff;
      border-color: #5fb469;

      .icon-size {
        position: relative;
        height: 17px;
        width: 17px;
        color: #fff;
        left: -1%;
        top: 3px;
        z-index: 5;
      }
      // end icon-size
    }
    // end button.add-itinerary
  }

  .existing-itineraries {
    display: flex;
    flex-wrap: wrap;

    h3 {
      margin-top: 0px !important;
    }
  }
}
#itinerary-cont {
  display: block;

  .itinerary-title-label {
    b {
      color: @colors[input-required];
    }
  }
}
</style>

<script>
import Vue from "vue";
import AddedItinerary from "../components/addedItinerary.vue";
import Itinerary from "../components/Itinerary.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faPlusSquare,
  faPlusCircle,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

library.add(faPlusSquare);
library.add(faPlusCircle);
library.add(faPlus);

export default {
  name: "itinerarySet",
  components: {
    AddedItinerary,
    Itinerary,
    FontAwesomeIcon,
  },
  props: {
    existingItinerary: Array,
    eventStartDate: String,
    eventStartTime: String,
    eventEndDate: String,
    eventEndTime: String,
    methodBus: Object,
    excludedDates: Array,
  },
  data() {
    return {
      bus: new Vue(),
      existingItineraries: this.existingItinerary,
      ItineraryIdCount: this.existingItinerary.length,
      itineraryToInject: [],
      errors: [{ name: "itineraries", optional: true, error: false }],
      itineraryUpdateMsg: "Save Itinerary/Set List Item",
      edit: false,
      createdIndex: this.existingItinerary.length,
      itineraryType: "",
      editedItineraryId: "",
    };
  },
  computed: {
    ItineraryIdCountToUse: {
      get() {
        let maxIdToUse = this.findMaxId();
        return maxIdToUse;
      },
      set(input) {
        if (!this.ItineraryIdCount) this.ItineraryIdCount = 0;
        this.ItineraryIdCount += input;
      },
    },
    displayItineriares() {
      console.log(
        JSON.stringify(this.existingItineraries),
        "<^----- this is the problem"
      );
      // return this.existingItineraries.sort((firstEle, secondEle) => {
      //     let firstEleStartDate = new Date(firstEle.dateArray[0].startDate);
      //     let firstEleEndDate = new Date(firstEle.dateArray[0].endDate);
      //     let secondEleStartDate = new Date(secondEle.dateArray[0].startDate);
      //     let secondEleEndDate = new Date(secondEle.dateArray[0].endDate);
      //     if (firstEleStartDate < secondEleStartDate) {
      //       return -1;
      //     } else {
      //       if (firstEle.dateArray.length > 1 && secondEle.dateArray.length > 1) {
      //         let firstEleSecondStartDate = new Date(
      //           firstEle.dateArray[firstEle.dateArray.length - 1].startDate
      //         );
      //         let secondEleSecondStartDate = new Date(
      //           secondEle.dateArray[secondEle.dateArray.length - 1].startDate
      //         );
      //         if (firstEleSecondStartDate < secondEleSecondStartDate) {
      //           return -1;
      //         } else {
      //           return 1;
      //         }
      //       } else {
      //         if (
      //           firstEle.dateArray.length > 1 &&
      //           secondEle.dateArray.length == 1
      //         ) {
      //           let firstEleSecondStartDate = new Date(
      //             firstEle.dateArray[firstEle.dateArray.length - 1].startDate
      //           );
      //           if (firstEleSecondStartDate > secondEleStartDate) {
      //             return 0;
      //           } else if(firstEleSecondStartDate < secondEleStartDate) {
      //             return -1;
      //           } else {
      //             return 1;
      //           }
      //         } else if (
      //           firstEle.dateArray.length == 1 &&
      //           secondEle.dateArray.length > 1
      //         ) {
      //           let SecondEleSecondStartDate = new Date(
      //             secondEle.dateArray[secondEle.dateArray.length - 1].startDate
      //           );
      //           if (SecondEleSecondStartDate > firstEleStartDate) {
      //             return 0;
      //           } else {
      //             return 1;
      //           }
      //         } else {
      //           let firstEleEndDate = new Date(firstEle.dateArray[0].endDate);
      //           let secondEleEndDate = new Date(secondEle.dateArray[0].endDate);
      //           if (firstEleEndDate > secondEleEndDate) {
      //             return 1;
      //           } else if (firstEleEndDate < secondEleEndDate) {
      //             return -1;
      //           } else {
      //             return 0;
      //           }
      //         }
      //       }
      //     }
      // });
      return this.existingItineraries.sort((firstEle, secondEle) => {
        let firstEleStartDate = new Date(firstEle.dateArray[0].startDate);
        let firstEleEndDate = new Date(firstEle.dateArray[0].endDate);
        let secondEleStartDate = new Date(secondEle.dateArray[0].startDate);
        let secondEleEndDate = new Date(secondEle.dateArray[0].endDate);
        if (firstEleStartDate.getTime() < secondEleStartDate.getTime()) {
          return -1;
        } else if (firstEleStartDate.getTime() > secondEleStartDate.getTime()) {
          return 1;
        } else {
          return 0;
        }
      });
    },
  },
  watch: {
    edit: function (val) {
      if (val) this.itineraryUpdateMsg = "Update Itinerary";
      else this.itineraryUpdateMsg = "Save Itinerary/Set List Item";
    },
    existingItinerary: function (arr) {
      //this.existingItineraries = arr;
      this.existingItineraries = arr;
    },
  },
  methods: {
    inputDataUpload(keyToSelect, idOfObject, objToAdd) {
      console.log(objToAdd);
      console.log(this.editedItineraryIndex);
      if (this.edit) {
        console.log("edit");
        //this.existingItineraries[this.editedItineraryIndex] = objToAdd;
        let existingIndexOfObj = this.existingItineraries.findIndex(
          (ele) => ele.id == idOfObject
        );
        this.existingItineraries[existingIndexOfObj] = objToAdd;
        // //forcing vue to re-render
        let arrayAfterIndex = this.existingItineraries.slice(
          existingIndexOfObj,
          this.existingItineraries.length
        );
        this.existingItineraries.splice(
          existingIndexOfObj,
          this.existingItineraries.length
        );
        this.existingItineraries.push(...arrayAfterIndex);
        this.edit = false;
        let argumentsToInsert = [idOfObject, objToAdd];
        //indexing creates weird results, best to use ids
        // this.existingItineraries[this.editedItineraryIndex].name = objToAdd.name;
        // this.existingItineraries[this.editedItineraryIndex].startTime = objToAdd.startTime;
        // this.existingItineraries[this.editedItineraryIndex].endTime = objToAdd.endTime;
        this.$emit("data", ...argumentsToInsert);
      } else {
        console.log("add");
        //this.existingItineraries.push(objToAdd)
        let argumentsToInsert = [idOfObject, objToAdd];
        this.$emit("data", ...argumentsToInsert);
      }
    },
    itineraryErrored(val) {
      this.$emit("itinerary-error", val);
    },
    removeItinerary(id) {
      let itinerary = this.existingItineraries.find((ele) => ele.id == id);
      if (this.itineraryToInject[0].id == itinerary.id) this.edit = false;
      this.$emit("remove-itinerary", itinerary.id);
    },
    saveItineraryInfo(objToSave) {
      if (objToSave.id == this.itineraryToInject[0].id) {
        this.edit = false;
      }
      this.itineraryToInject.pop();
      objToSave.id -= 1;
      this.itineraryToInject.unshift(objToSave);
    },
    addItinerary() {
      console.log("add/edit", this.$children);
      this.bus.$emit("save-itinerary-data");
      let errorHandler = false;
      for (let i = 0; i < this.$children.length; i++) {
        let component = this.$children[i];
        if (component.$el.classList.contains("itinerary")) {
          errorHandler = component.errors.some((ele) => ele.error == true);
        }
      }
      if (!errorHandler) {
        this.itineraryType = "";
        this.createdIndex++;
        // this.existingItineraries[this.editedItineraryIndex] =
        this.itineraryToInject.shift();
        this.itineraryToInject.push({ id: this.ItineraryIdCountToUse });
        this.$emit("itinerary-error", false);
      }
    },
    updateItinerary(id) {
      let itinerary = this.existingItineraries.find((ele) => ele.id == id);
      this.editedItineraryId = itinerary.id;
      this.itineraryToInject.shift();
      this.itineraryToInject.push(itinerary);
      this.edit = true;
      this.itineraryType = "edit";
    },
    buildIds() {
      for (let i = 0; i < this.existingItineraries.length; i++) {
        let itinerary = this.existingItineraries[i];
        itinerary.id = i;
      }
    },
    findMaxId() {
      let maxIds = 0;
      for (let i = 0; i < this.existingItineraries.length; i++) {
        let indexToUse = i + 1;
        let idToKeep = this.existingItineraries[i].id;
        if (indexToUse == this.existingItineraries.length - 1) {
          i += this.existingItineraries.length;
          if (idToKeep < this.existingItineraries[indexToUse].id) {
            idToKeep = this.existingItineraries[indexToUse].id;
          }
        } else if (indexToUse == this.existingItineraries.length) {
        } else {
          if (idToKeep < this.existingItineraries[indexToUse].id) {
            idToKeep = this.existingItineraries[indexToUse].id;
          }
          i += 1;
        }
        if (idToKeep > maxIds) {
          maxIds = idToKeep + 1;
        } else if (idToKeep === maxIds) {
          maxIds++;
        }
      }
      return maxIds;
    },
  },
  beforeMount() {
    this.itineraryToInject.push({ id: this.ItineraryIdCountToUse });
    if (
      this.existingItineraries[0] &&
      this.existingItineraries[0].dateArray &&
      typeof this.existingItineraries[0].dateArray.startDate == "object"
    ) {
      console.log("Look at me im a fool");
      this.buildIds();
    }
  },
  mounted() {
    if (this.methodBus) {
      this.methodBus.$on("itinerary-error", (args) => {
        this.bus.$emit("itinerary-error-check");
      });
      this.methodBus.$on("itinerary-upload", (args) => {
        this.addItinerary();
        // this.bus.$emit('itinerary-data')
      });
    }
  },
};
</script>
