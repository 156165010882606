<template>
  <div>
    <div class="slider-info-buttons">
      <font-awesome-icon
        icon="caret-left"
        class="slider-button prev"
        v-if="currentIndex != 0"
        v-on:click="swapSliderInfo(-1)"
      />
      <p
        class="slider-info-button-index"
        :id="'slider-info-index-' + indexOfObj"
        v-if="!singleDate"
      >{{ currentIndex + 1 }}</p>
      <font-awesome-icon
        icon="caret-right"
        class="slider-button next"
        v-if="currentIndex != info.length - 1"
        v-on:click="swapSliderInfo(1)"
      />
    </div>
    <div class="slider-info" :class="{extend: singleDate}">
      <span :startDate="startDate">
        <strong>Start Date:</strong>
        {{ startDate }}
      </span>
      <span :startDate="startDate">
        <strong>Start Time:</strong>
        {{ startTime }}
      </span>
      <span :endDate="endDate">
        <strong>End Date:</strong>
        {{ endDate }}
      </span>
      <span :endDate="endDate">
        <strong>End Time:</strong>
        {{ endTime }}
      </span>
    </div>
  </div>
</template>

<style lang="less" scoped>
.slider-info-buttons {
  display: flex;
  justify-content: center;
  text-align: center;
  p.slider-info-button-index {
    height: fit-content;
    width: fit-content;
    margin: 0px !important;
    position: absolute;
  }
  .slider-button {
    //margin-top: 7px;
    color: #4cac55;
    font-size: 34px;
    z-index: 10;
    cursor: pointer;
  }
  .next {
    position: relative;
    bottom: 5px;
    left: 23px;
  }
  .prev {
    position: relative;
    right: 23px;
    bottom: 5px;
  }
}
.slider-info {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0px;
  flex-flow: row wrap;
}

.extend {
  margin-top: 35px;
}
p {
  font-size: 15px !important;
}
</style>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import dateFormat from "dateformat";
export default {
  components: {
    FontAwesomeIcon
  },
  props: {
    info: Array,
    indexOfObj: Number
  },
  data() {
    return {
      currentIndex: 0
    };
  },
  methods: {
    swapSliderInfo(modifier) {
      if (this.currentIndex == 0) this.currentIndex++;
      else if (this.currentIndex + modifier > this.info.length - 1) 1 + 1;
      else this.currentIndex += modifier;
    },
    objDate(val) {
      if (typeof val != "object") {
        return dateFormat(new Date(val), "ddd, mmm dS, yyyy, hh:MM TT");
      } else {
        return dateFormat(
          new Date(+val.$date.$numberLong),
          "ddd, mmm dS, yyyy, hh:MM TT"
        );
      }
    }
  },
  computed: {
    singleDate() {
      return this.info.length <= 1;
    },
    arrayOfDateObj() {
      return this.info;
    },
    startDate() {
      let self = this;
      let date = this.info[this.currentIndex];
      let val = date.startDate;
      console.log(val);
      if (typeof val != "object") {
        console.log("1");
        return dateFormat(
          new Date(val.replace(/-/g, "/")),
          "ddd, mmm dS, yyyy"
        );
      } else {
        console.log("2");
        return dateFormat(
          new Date(+val.$date.$numberLong),
          "ddd, mmm dS, yyyy"
        );
      }
    },
    startTime() {
      let self = this;
      let date = this.info[this.currentIndex];
      let val = date.startDate;
      console.log(val);
      if (typeof val != "object") {
        return dateFormat(new Date(val.replace(/-/g, "/")), "hh:MM TT");
      } else {
        return dateFormat(new Date(+val.$date.$numberLong), "hh:MM TT");
      }
    },
    endDate() {
      let date = this.info[this.currentIndex];
      let val = date.endDate;
      console.log(val);
      if (typeof val != "object") {
        return dateFormat(
          new Date(val.replace(/-/g, "/")),
          "ddd, mmm dS, yyyy"
        );
      } else {
        return dateFormat(
          new Date(+val.$date.$numberLong),
          "ddd, mmm dS, yyyy"
        );
      }
    },
    endTime() {
      let date = this.info[this.currentIndex];
      let val = date.endDate;
      console.log(val);
      if (typeof val != "object") {
        return dateFormat(new Date(val.replace(/-/g, "/")), "hh:MM TT");
      } else {
        return dateFormat(new Date(+val.$date.$numberLong), "hh:MM TT");
      }
    }
  }
};
</script>