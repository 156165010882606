<template>
  <div class="time-picker-wrapper">
    <label :class="{ error: error }">
      {{ label }}
      <b v-if="required">*</b>
    </label>
    <input
      type="text"
      class="time-input"
      placeholder="HH:MM A/P"
      readonly
      v-model="combinedTime"
      :class="[error ? 'error' : '', inputClass ? inputClass : '']"
      :val="combinedTime"
      v-on:focus="showTimer(timerWrapperId)"
      v-on:blur="$emit('updateTime', combinedTime)"
      :tabindex="tabIndex"
    />
    <div class="time-dropdown" :id="timerWrapperId">
      <div class="hours">
        <div class="cell-header">HH</div>
        <div
          class="cell"
          v-for="(hour, index) in hours"
          :key="index"
          v-on:click="setHour(hour)"
        >
          {{ hour }}
        </div>
      </div>
      <div class="minutes">
        <div class="cell-header">MM</div>
        <div
          class="cell"
          v-for="(minute, index) in minutes"
          :key="index"
          v-on:click="setMinute(minute)"
        >
          {{ minute }}
        </div>
      </div>
      <div class="ampm">
        <div class="cell-header">A/P</div>
        <div
          class="cell"
          v-for="(val, index) in ampm"
          :key="index"
          v-on:click="setAmPm(val)"
        >
          {{ val }}
        </div>
      </div>
    </div>
    <div class="error-msg" v-if="error">
      <div class="error-msg-inner">
        <font-awesome-icon class="icon" icon="exclamation-triangle" />
        <template v-if="typeof error === 'string'">{{ error }}</template>
        <template v-else>{{ label }} requires a valid input.</template>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.time-picker-wrapper {
  position: relative;
  margin-bottom: 1.5em;
  display: flex;
  flex-wrap: wrap;
  overflow: visible;

  label {
    display: block;
    flex-basis: 100%;
    margin-bottom: 0.5em;
    font-size: 16px;
    font-weight: 200;

    b {
      padding-left: 2px;
      color: @colors[input-required];
    }

    &.error {
      color: #e60022;
    }
  }

  .time-input {
    display: block;
    padding: 12px 0px;
    background: var(--input-background);
    text-indent: 14px;
    width: 100%;
    height: 24px;
    font-family: inherit;
    font-size: 1em;
    color: var(--text);
    // background-color: #181818;
    // border: 1px  ;
    border: 1px solid transparent;
    border-radius: 5px;
    box-shadow: 1px 1px 2px -1px rgba(255, 255, 255, 0.15),
      inset 1px 1px 3px 0px rgba(0, 0, 0, 0.15);
    outline: none;
    -webkit-appearance: none;
    transition: all 0.4s ease, visibility 0s;

    &:hover {
      border: 1px solid var(--input-border);
    }
    &:focus {
      // padding-left: 16px;
      // padding-right: 12px;
      color: var(--text);
      border: 1px solid #48bb8b;
      text-indent: 32px;
      background-color: var(--input-background);
    }

    &.error {
      background-color: #1a0306;
      &:focus {
        background-color: #47060f;
      }
    }
  }

  .time-dropdown {
    position: absolute;
    display: flex;
    // margin-left: 6px;
    transition: 1s;
    width: 150px;
    top: 75px;
    height: 0px;
    overflow: hidden;
    border: solid 1px var(--input-border);
    border-radius: 10px;
    z-index: 15;
    visibility: hidden;

    .minutes,
    .hours,
    .ampm {
      display: flex;
      flex-wrap: wrap;
      overflow: scroll;
      width: 34%;
      align-content: flex-start;
      background-color: var(--input-background);
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }

      .cell,
      .cell-header {
        color: #4eb95e;
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .cell-header {
        color: #4a639e;
      }

      .cell {
        cursor: pointer;

        &:hover {
          background-color: var(--time-hover);
        }
      }
    }
  }

  .error-msg {
    position: relative;
    width: 100%;
    top: -1px;
    margin: 0 6px;
    border: 1px solid #1a0306;
    border-top: 1px solid transparent;
    border-radius: 0 0 5px 5px;
    background: #4e2e33;
    transition: 0.4s;

    .error-msg-inner {
      padding: 2px 5px 4px 5px;

      .icon {
        padding-right: 5px;
      }
    }
  }
}
// end event-date
</style>

<script>
import DiyoboInput from "@/components/DiyoboInput.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faExclamationTriangle);

export default {
  name: "time",
  components: {
    DiyoboInput,
    FontAwesomeIcon
  },
  data() {
    return {
      time: this.val,
      lastClicked: "",
      hours: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12"
      ],
      hourBool: false,
      minutes: ["00", "15", "30", "45"],
      minuteBool: false,
      ampm: ["AM", "PM"],
      ampmBool: false,
      timeSet: false,
      dispHour: "",
      dispMinute: "",
      dispAmPm: "",
      tempTime: "",
      openMenuId: ""
    };
  },
  props: {
    val: String,
    error: Boolean | String,
    timerWrapperId: String,
    required: Boolean,
    label: String,
    inputClass: String,
    tabIndex: String
  },
  computed: {
    combinedTime: function() {
      if (this.dispHour != "" && this.dispMinute != "" && this.dispAmPm != "") {
        this.$emit(
          "updateTime",
          this.dispHour + ":" + this.dispMinute + " " + this.dispAmPm
        );
        if (!this.timeSet) {
          this.timeSet = true;
          this.hideTimer(this.timerWrapperId);
        }
      }

      if (this.dispHour == "" && this.dispMinute == "" && this.dispAmPm == "") {
        return "";
      } else {
        return this.dispHour + ":" + this.dispMinute + " " + this.dispAmPm;
      }
    }
  },
  methods: {
    showTimer(id) {
      let test = document.getElementsByClassName("time-dropdown");

      Array.from(test).forEach(ele => {
        ele.style.height = "0px";
        ele.style.top = "75px";
        ele.style.visibility = "hidden";
      });

      document.getElementById(id).style.visibility = "visible";
      document.getElementById(id).style.height = "100px";
      document.getElementById(id).style.top = "85px";
    },
    hideTimer(id) {
      let heightEle = document.getElementById(id);
      let topEle = document.getElementById(id);
      if (heightEle || topEle) {
        heightEle.style.height = "0px";
        topEle.style.top = "75px";
      }
      let timer = document.getElementById(id);
      if (timer) {
        timer.style.visibility = "hidden";
      }
    },
    setHour(val) {
      this.dispHour = val;
      this.hourBool = true;
    },
    setMinute(val) {
      this.dispMinute = val;
      this.minuteBool = true;
    },
    setAmPm(val) {
      this.dispAmPm = val;
      this.ampmBool = true;
    }
  },
  watch: {
    val(val) {
      if (val.length == 8) {
        let splitSpace = this.val.split(" ");
        this.dispAmPm = splitSpace[1];
        let splitColon = splitSpace[0].split(":");
        this.dispHour = splitColon[0];
        this.dispMinute = splitColon[1];
      } else {
        this.dispAmPm = "";
        this.dispHour = "";
        this.dispMinute = "";
      }
    }
  },
  mounted() {
    let self = this;
    console.log(this.val);
    if (this.val) {
      let splitSpace = this.val.split(" ");
      this.dispAmPm = splitSpace[1];
      let splitColon = splitSpace[0].split(":");
      this.dispHour = splitColon[0];
      this.dispMinute = splitColon[1];

      this.combinedTime = this.val;
    }

    document.addEventListener("click", function(e) {
      if (e.target.classList.contains(self.inputClass)) {
        return;
      } else if (
        e.target.classList.contains("time-dropdown") ||
        e.target.classList.contains("cell") ||
        e.target.classList.contains("minutes") ||
        e.target.classList.contains("hours") ||
        e.target.classList.contains("ampm")
      ) {
        return;
      } else {
        self.hideTimer(self.timerWrapperId);
      }
    });
  }
};
</script>
