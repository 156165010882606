<template>
  <div class="event-financials">
    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner">
        <section>
          <p>
            <router-link to="/dashboard">
              Dashboard > AutomatedSurveys
            </router-link>
          </p>
        </section>
        <section class="dropdown-container">
          <div class="event-container">
            <diyobo-input
              v-if="eventsAreReady"
              label="Select Your Event:"
              placeholder="Select Your Event"
              type="dropdown"
              v-model="chosenEvent"
              :val="chosenEvent"
              :options="eventsToDisplay"
              ref="eventsToSelect"
            />
          </div>
          <div class="automation-container">
            <diyobo-input
              v-if="eventsAreReady"
              label="Select Your Automation:"
              placeholder="Select Your Automation"
              type="dropdown"
              v-model="chosenEvent"
              :val="chosenEvent"
              :options="automationToDisplay"
              ref="eventsToSelect"
            />
          </div>
        </section>
        <section class="survery-container" v-if="eventSelected">
          <div class="input-wrapper">
            <wysiwyg
              label="Survey"
              :required="true"
              v-model="description"
              :value="description"
              :error="error('description')"
            />
          </div>
        </section>
        <section class="itneray-container" v-if="eventSelected">
          <div class="col full">
            <div class="input-wrapper">
              <div class="check-wrap">
                <diyobo-checkbox
                  type="check"
                  label="My event has a itinerary / Set list."
                  v-model="hasItineraries"
                  :checked="hasItineraries"
                />
              </div>
            </div>
          </div>
          <!-- <div id="itinerary-cont" v-if="hasItineraries">
          <label>Itinerary/Set List *</label>
            <div class="itinerary-wrapper" v-for="(itinerary, index) in itineraries" :key="itinerary.id">
              <itinerary 
                :info="itinerary" 
                :bus="bus" 
                :id="itinerary.id"
                :index="index"
                v-on:data="data('itineraries', ...arguments)" 
                v-on:errored="itineraryErrored"
                v-on:removeItinerary="removeItinerary(itinerary.id)"
              />
            </div>
            <div class="add-itinerary-wrapper">
              <button class="add-itinerary" v-on:click="addItinerary" >
              <font-awesome-icon class="icon-size" icon="plus" />
                Add Another Itinerary / Set List
              </button>
            </div>
          </div>
          <AddItinerary/>-->
          <ItinerarySet
            :existingItinerary="[]"
            v-on:data="data('itineraries', ...arguments)"
          />
        </section>
        <section class="existing-container"></section>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
#Content {
  p {
    color: #868686;
    a {
      color: #868686;
    }
  }
}
</style>

<script>
import Vue from "vue";
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import wysiwyg from "../components/WYSIWYG.vue";
import Itinerary from "../components/Itinerary.vue";
import DiyoboCheckbox from "@/components/DiyoboCheckbox.vue";
import AddItinerary from "../components/addedItinerary.vue";
import ItinerarySet from "../components/ItinerarySet.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faPlusSquare,
  faPlusCircle,
  faPlus
} from "@fortawesome/free-solid-svg-icons";

library.add(faPlusSquare);
library.add(faPlusCircle);
library.add(faPlus);

export default {
  name: "survey",
  components: {
    wysiwyg,
    DiyoboInput,
    DiyoboButton,
    Itinerary,
    FontAwesomeIcon,
    DiyoboCheckbox,
    AddItinerary,
    ItinerarySet
  },
  head() {
    return {
      title: "Automated Surveys"
    };
  },
  data() {
    return {
      bus: new Vue(),
      eventsToDisplay: [],
      automationToDisplay: [],
      itineraries: [],
      existingSurveys: [],
      eventsAreReady: false,
      surveysAreReady: false,
      description: "",
      errors: [
        { name: "description", display: "Event Survey", error: false },
        { name: "itineraries", optional: true, error: false }
      ],
      hasItineraries: false,
      eventSelected: false,
      events: []
    };
  },
  computed: {
    email() {
      return this.$store.state.user.email;
    },
    chosenEvent: {
      get() {
        return "";
      },
      set(eventName) {
        let event = {};
        this.events.forEach(element => {
          if (element.name == eventName) {
            event = { ...element };
          }
        });
        if (event != {}) {
          this.eventSelected = true;
        }
        console.log(event);
      }
    }
  },
  methods: {
    setEventData(EventArray) {
      let eventsToAdd = [];
      if (EventArray.length > 0) {
        EventArray.forEach(element => {
          this.eventsToDisplay.push({
            label: element.name,
            value: element.name
          });
          // if(element.surveys.length > 0)
          // {
          //   element.surveys.forEach(survey => {
          //     this.existingSurveys.push({eventName: element.name, automationName: survey.automation.name})
          //   })
          // }
        });
        console.log(this.eventsToDisplay);
        this.eventsAreReady = true;
        this.surveysAreReady = true;
      }
    },
    error(input) {
      let error = this.errors.find(e => e.name === input);
      return error && error.error;
    },
    data(key1, key2, value) {
      console.log(key1, key2, value, "Hello from data");
      //console.log(this[key1], key2)
      this[key1][key2] = value;
    },
    removeItinerary(val) {
      for (let x = 0; x < this.itineraries.length; x++) {
        if (this.itineraries[x].id == val) {
          this.itineraries.splice(x, 1);
        }
      }
    },
    itineraryErrored() {
      if (this.errors[1].optional == false) {
        this.errors[1].error = true;
      }
    },
    submitSurvey() {
      this.bus.$emit("itinerary-data");
    },
    selectEvent(eventName) {
      let event = {};
      this.events.forEach(element => {
        if (element.name == eventName) {
          event = { ...element };
        }
      });
      console.log(event);
    },
    addItinerary() {
      if (this.handleData(true)) {
        let length = this.itineraries.length;
        let id;
        if (length == 0) {
          id = length + 1;
        } else {
          id = this.itineraries[length - 1].id + 1;
        }

        this.itineraries.push({
          id: id
        });
      }
    },
    handleData(val) {
      this.errors.forEach(e => {
        e.errors = [];
        if (!e.optional) {
          e.error = !this[e.name];
          if (e.error)
            if (val) {
              e.error = false;
            } else {
              e.errors.push(`${e.display} is required`);
            }
        }
      });
      if (this.errors[1].optional == true) {
        this.errors[1].error = false;
      }
      console.log("About to emit my bus");
      this.bus.$emit("itinerary-data");

      if (this.errors[1].error) {
        this.errors[1].errors.push("Invalid itineraries");
      }
      // console.log(this.errors)
      // console.log(!this.errors.some(e => e.error))
      return !this.errors.some(e => e.error);
    }
  },
  created() {
    let emailObj = { email: this.email };
    console.log(JSON.stringify(emailObj));
    this.$store.commit("setTitle", "Dashboard - Survey Automation");
    this.$axios
      .post(
        `${process.env.VUE_APP_SERVICE_URL}get-events-and-surveys`,
        emailObj
      )
      .then(response => {
        if (response.status === 200) {
          this.setEventData(response.data);
          this.events.push(...response.data);
        }
      });
  }
};
</script>
