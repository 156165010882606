<template>
    <p v-if="errors.filter(e => e.error).length">
        <b>Please correct the following error(s):</b>
        <ul>
            <div v-for="error in errors" :key="error.name">
                <li v-for="msg in error.errors" :key="msg">{{ msg }}</li>
            </div>
        </ul>
    </p>
</template>
<style lang="less" scoped>
    p {
        margin-bottom: 40px !important;
    }
</style>
<script>
export default {
    name: 'error-list',
    props: {
        errors: Array
    },
    mounted() {
        // console.log(this.errors)
    }
}
</script>
