<template>
  <div class="event-calendar" :id="`${idToUse}`">
    <label class="calendar-label" :class="{ error: error }">
      Event Calendar
      <b>*</b>
    </label>
    <div
      class="calendar-container"
      :class="{ error: error }"
      v-for="date in dates"
      :key="date.month"
    >
      <div class="month-wrapper">
        <span>{{ date.month }}</span>
      </div>
      <div class="days-wrapper">
        <div class="day">Sun</div>
        <div class="day">Mon</div>
        <div class="day">Tue</div>
        <div class="day">Wed</div>
        <div class="day">Thu</div>
        <div class="day">Fri</div>
        <div class="day">Sat</div>
      </div>
      <div class="grid">
        <div class="week" v-for="week in date.weeks" :key="week[0]">
          <div
            :class="
              eventDate(week[0], date.daysToCheck)
                ? `day active data`
                : `day inactive data`
            "
            v-on:click="tierToggle($event, date)"
            :id="`${date.month}-${week[0]}-${date.year}`"
          >
            {{ week[0] }}
            <div class="hover-wrapper" v-if="showTimes">
              <div
                class="info-hover"
                :id="`${date.month}-${week[0]}-${date.year}-info`"
              >
                <div class="info-inner">
                  <p v-if="checkDay(week[0], date.daysToCheck, 'first')">
                    Start: {{ eventStartTime }}
                  </p>
                  <p v-else>Start: 12:00 AM</p>
                  <p v-if="checkDay(week[0], date.daysToCheck, 'last')">
                    End: {{ eventEndTime }}
                  </p>
                  <p v-else>End: 11:59 PM</p>
                </div>
              </div>
              <div class="pointer"></div>
            </div>
          </div>
          <div
            :class="
              eventDate(week[1], date.daysToCheck)
                ? `day active data`
                : `day inactive data`
            "
            v-on:click="tierToggle($event, date)"
            :id="`${date.month}-${week[1]}-${date.year}`"
          >
            {{ week[1] }}
            <div class="hover-wrapper" v-if="showTimes">
              <div
                class="info-hover"
                :id="`${date.month}-${week[1]}-${date.year}-info`"
              >
                <div class="info-inner">
                  <p v-if="checkDay(week[1], date.daysToCheck, 'first')">
                    Start: {{ eventStartTime }}
                  </p>
                  <p v-else>Start: 12:00 AM</p>
                  <p v-if="checkDay(week[1], date.daysToCheck, 'last')">
                    End: {{ eventEndTime }}
                  </p>
                  <p v-else>End: 11:59 PM</p>
                </div>
              </div>
              <div class="pointer"></div>
            </div>
          </div>
          <div
            :class="
              eventDate(week[2], date.daysToCheck)
                ? `day active data`
                : `day inactive data`
            "
            v-on:click="tierToggle($event, date)"
            :id="`${date.month}-${week[2]}-${date.year}`"
          >
            {{ week[2] }}
            <div class="hover-wrapper" v-if="showTimes">
              <div
                class="info-hover"
                :id="`${date.month}-${week[2]}-${date.year}-info`"
              >
                <div class="info-inner">
                  <p v-if="checkDay(week[2], date.daysToCheck, 'first')">
                    Start: {{ eventStartTime }}
                  </p>
                  <p v-else>Start: 12:00 AM</p>
                  <p v-if="checkDay(week[2], date.daysToCheck, 'last')">
                    End: {{ eventEndTime }}
                  </p>
                  <p v-else>End: 11:59 PM</p>
                </div>
              </div>
              <div class="pointer"></div>
            </div>
          </div>
          <div
            :class="
              eventDate(week[3], date.daysToCheck)
                ? `day active data`
                : `day inactive data`
            "
            v-on:click="tierToggle($event, date)"
            :id="`${date.month}-${week[3]}-${date.year}`"
          >
            {{ week[3] }}
            <div class="hover-wrapper" v-if="showTimes">
              <div
                class="info-hover"
                :id="`${date.month}-${week[3]}-${date.year}-info`"
              >
                <div class="info-inner">
                  <p v-if="checkDay(week[3], date.daysToCheck, 'first')">
                    Start: {{ eventStartTime }}
                  </p>
                  <p v-else>Start: 12:00 AM</p>
                  <p v-if="checkDay(week[3], date.daysToCheck, 'last')">
                    End: {{ eventEndTime }}
                  </p>
                  <p v-else>End: 11:59 PM</p>
                </div>
              </div>
              <div class="pointer"></div>
            </div>
          </div>
          <div
            :class="
              eventDate(week[4], date.daysToCheck)
                ? `day active data`
                : `day inactive data`
            "
            v-on:click="tierToggle($event, date)"
            :id="`${date.month}-${week[4]}-${date.year}`"
          >
            {{ week[4] }}
            <div class="hover-wrapper" v-if="showTimes">
              <div
                class="info-hover"
                :id="`${date.month}-${week[4]}-${date.year}-info`"
              >
                <div class="info-inner">
                  <p v-if="checkDay(week[4], date.daysToCheck, 'first')">
                    Start: {{ eventStartTime }}
                  </p>
                  <p v-else>Start: 12:00 AM</p>
                  <p v-if="checkDay(week[4], date.daysToCheck, 'last')">
                    End: {{ eventEndTime }}
                  </p>
                  <p v-else>End: 11:59 PM</p>
                </div>
              </div>
              <div class="pointer"></div>
            </div>
          </div>
          <div
            :class="
              eventDate(week[5], date.daysToCheck)
                ? `day active data`
                : `day inactive data`
            "
            v-on:click="tierToggle($event, date)"
            :id="`${date.month}-${week[5]}-${date.year}`"
          >
            {{ week[5] }}
            <div class="hover-wrapper" v-if="showTimes">
              <div
                class="info-hover"
                :id="`${date.month}-${week[5]}-${date.year}-info`"
              >
                <div class="info-inner">
                  <p v-if="checkDay(week[5], date.daysToCheck, 'first')">
                    Start: {{ eventStartTime }}
                  </p>
                  <p v-else>Start: 12:00 AM</p>
                  <p v-if="checkDay(week[5], date.daysToCheck, 'last')">
                    End: {{ eventEndTime }}
                  </p>
                  <p v-else>End: 11:59 PM</p>
                </div>
              </div>
              <div class="pointer"></div>
            </div>
          </div>
          <div
            :class="
              eventDate(week[6], date.daysToCheck)
                ? `day active data`
                : `day inactive data`
            "
            v-on:click="tierToggle($event, date)"
            :id="`${date.month}-${week[6]}-${date.year}`"
          >
            {{ week[6] }}
            <div class="hover-wrapper" v-if="showTimes">
              <div
                class="info-hover"
                :id="`${date.month}-${week[6]}-${date.year}-info`"
              >
                <div class="info-inner">
                  <p v-if="checkDay(week[6], date.daysToCheck, 'first')">
                    Start: {{ eventStartTime }}
                  </p>
                  <p v-else>Start: 12:00 AM</p>
                  <p v-if="checkDay(week[6], date.daysToCheck, 'last')">
                    End: {{ eventEndTime }}
                  </p>
                  <p v-else>End: 11:59 PM</p>
                </div>
              </div>
              <div class="pointer"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.event-calendar {
  display: flex;
  flex-wrap: wrap;
  //width: 420px;

  .calendar-label {
    display: block;
    flex-basis: 100%;
    margin-bottom: 0.5em;
    font-size: 16px;
    font-weight: 200;
    &.error {
      color: #e60022;
    }
    b {
      padding-left: 2px;
      color: @colors[input-required];
    }
  }

  .calendar-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-right: 17.3px;
    margin-bottom: 17.3px;
    //width: 260px;
    width: 100%;
    height: 100%;
    border: solid 1px grey;

    &.error {
      border-color: #e60022;
    }

    &:last-of-type {
      margin-right: 0px;
    }

    .month-wrapper {
      width: calc(100% - 6px);
      display: flex;
      align-items: center;
      background-color: #212121;
      padding: 4px 5px;
      border: solid 1px #4e4e4e;
      height: 25px;
      width: 100%;
      font-size: 20px;
    }

    .days-wrapper {
      display: flex;
      width: 100%;
      background-color: #212121;

      .day {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #4e4e4e;
        width: 14.28%;
        height: 25px;
        font-size: 18px;
      }
    }

    .grid {
      display: flex;
      flex-wrap: wrap;
      background-color: #212121;
      width: 100%;

      .week {
        display: flex;
        width: 100%;

        .day {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #4e4e4e;
          width: 14.28%;
          height: 25px;
          font-size: 16px;
          cursor: pointer;
          background-color: #4a639e;
        }

        .day.active {
          position: relative;
          &:hover {
            .hover-wrapper {
              opacity: 1;
              transition: 0.5s;
              visibility: visible;
            }
          }

          .hover-wrapper {
            display: flex;
            flex-wrap: wrap;
            opacity: 0;
            transition: 0.5s;
            visibility: hidden;
            width: 100px;
            height: 50px;
            position: absolute;
            top: -61px;
            left: 5px;

            .info-hover {
              background-image: linear-gradient(160deg, #4eb95e, #4a639e);
              width: 100%;
              height: 100%;
              border-radius: 10px;
              top: -60px;
              padding: 2px;
              z-index: 5;

              .info-inner {
                border-radius: 10px;
                background-color: #171717e0;
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;
                width: 100%;
                height: 100%;

                p {
                  margin: 0px;
                }
              }
            }

            .pointer {
              background-image: linear-gradient(160deg, #4b7a8e, #4b7194);
              padding: 2px;
              width: 15px;
              height: 15px;
              transform: rotate(-45deg);
              position: absolute;
              bottom: -12px;
              left: 41px;
            }
          }
        }

        .day.inactive {
          background-color: #3a3a3a;
          cursor: default;

          &.error {
            background-color: #1a0306;
          }

          .info-hover {
            display: none;
          }
        }

        .day.selected {
          &.green {
            background-color: #4eb95d;
          }
          &.grey {
            background-color: #3a3a3a;
          }
        }
      }
    }
  }
}
</style>

<script>
import dateFormat from "dateformat";

export default {
  name: "event-calendar",
  components: {},
  props: {
    dates: Array,
    savedDates: Array,
    excludedDates: Array,
    error: Boolean,
    reApplySavedDates: Number,
    eventStartTime: String,
    eventEndTime: String,
    showTimes: Boolean,
    changeColour: String,
    UniqueId: String,
    parentBus: Object
  },
  data() {
    return {
      displayedData: [],
      startDates: [],
      datesToClick: [],
      datesToSave: []
      // excludedDatesToUse: this.excludedDates,
    };
  },
  methods: {
    //event dates is causing us issues
    checkDay(day, datesArr, index) {
      let response = false;

      //console.log(day, datesArr[0], datesArr[datesArr.length - 1], index);

      if (parseInt(day) == parseInt(datesArr[0]) && index == "first") {
        response = true;
        //console.log("start");
      } else if (day == datesArr[datesArr.length - 1] && index == "last") {
        response = true;
        //console.log("end");
      }

      return response;
    },
    eventDate(day, arrayToCompareAgainst) {
      let response = false;

      let dayToFind = arrayToCompareAgainst.find(ele => ele == parseInt(day));
      if (dayToFind) {
        response = true;
      }
      return response;
    },
    tierToggle(e, date) {
      let self = this;
      let valid = false;
      let dayToUse = e.target.innerHTML.split(" ")[1];
      //console.log(typeof dayToUse, dayToUse, e.target.innerHTML);
      let dateUpdate = dateFormat(
        new Date(date.month + dayToUse + "," + date.year),
        "mmmm dd, yyyy"
      );
      let dayFound = date.daysToCheck.find(ele => ele == parseInt(dayToUse));
      if (dayFound) {
        valid = true;
      }
      let newDateObj = {
        month: date.month,
        year: date.year,
        day: dayToUse.trim()
      };
      if (!e.target.classList.contains("inactive")) {
        if (valid) {
          if (!e.target.classList.contains("selected")) {
            e.target.classList.add("selected");
            e.target.classList.add(self.backgroundColourClass);
            if (self.backgroundColourClass == "grey") {
              if (this.parentBus) {
                // this.parentBus.$emit(
                //   "check-me-out-test",
                //   ...["Fool", "Baka-desu", { MangoTrees: "Peach Lemons" }]
                // );
              }
            }
            let exists = false;
            exists = this.savedDatesToUse.some(
              ele =>
                ele.year == newDateObj.year &&
                ele.month == newDateObj.month &&
                ele.day == newDateObj.day
            );

            if (!exists) {
              console.log("tag me");
              //if (this.startDates.length > 0)  //this.removeLastDate();
              this.datesToSave.push(dateUpdate);

              this.savedDatesToUse.push(newDateObj);
            }

            //this.$emit("dates", this.startDates);
          } else {
            e.target.classList.remove("selected");
            e.target.classList.remove(self.backgroundColourClass);
            let arrayToInject = this.savedDatesToUse.filter(ele => {
              if (
                ele.year == newDateObj.year &&
                ele.month == newDateObj.month &&
                ele.day == newDateObj.day
              ) {
                return false;
              } else {
                return ele;
              }
            });
            this.savedDatesToUse.splice(
              0,
              this.savedDatesToUse.length,
              ...arrayToInject
            );
          }
        }
      }
    },
    showSelectedSaveDates() {
      let self = this;
      let ourCalendar = document.querySelector(`#${self.idToUse}`);
      if (ourCalendar) {
        this.savedDatesToUse.forEach((date, index) => {
          let dayToUse;
          if (parseInt(date.day) < 10) {
            dayToUse = date.day.replace("0", "");
          } else {
            dayToUse = date.day;
          }
          let stringToUse = `#${date.month}-${dayToUse}-${date.year}`;
          let dayFound = ourCalendar.querySelector(stringToUse);
          console.log("Look at me im a pretty princess");
          let crazyTest = ourCalendar.querySelector(
            `#${date.month}-${dayToUse}-${date.year}`
          );
          if (dayFound) {
            dayFound.classList.add("selected");
            dayFound.classList.add(self.backgroundColourClass);
            console.log(
              "Oi she blows! SHE BLOWS! AHRHGHGHGHG",
              dayFound,
              index
            );
          }
        });
      }
    },
    showAnyErrors() {
      let self = this;
      if (this.error) {
        let ourCalendar = document.querySelector(`#${self.idToUse}`);
        if (ourCalendar) {
          let inactiveDays = ourCalendar.querySelectorAll(".inactive.data");
          for (let i = 0; i < inactiveDays.length; i++) {
            let element = inactiveDays[i];
            element.classList.add("error");
          }
        }
      }
    },
    greyOutExcludedDates() {
      let self = this;
      let ourCalendar = document.querySelector(`#${self.idToUse}`);
      if (ourCalendar) {
        let days = ourCalendar.querySelectorAll(".day");
        for (let i = 0; i < days.length; i++) {
          let day = days[i];
          if (day) {
            if (day.id) {
              let elementsDate = day.id;
              let dayCheck = elementsDate.split("-")[1];
              let foundDate = false;
              self.excludedDatesToUse.forEach(date => {
                if (date.day && date.month && date.year) {
                  let stringDateToCheck =
                    date.month + "-" + date.day + "-" + date.year;
                  if (elementsDate == stringDateToCheck) {
                    day.classList.add("inactive");
                    foundDate = true;
                  }
                }
              });
              if (
                day.classList.contains("inactive") &&
                !foundDate &&
                dayCheck &&
                dayCheck != "undefined"
              ) {
                day.classList.remove("inactive");
              }
            }
          }
        }
      }
    },
    removeHighlightedDays() {
      let self = this;
      let ourCalendar = document.querySelector(`#${self.idToUse}`);
      if (ourCalendar) {
        let days = ourCalendar.querySelectorAll(".day");
        for (let i = 0; i < days.length; i++) {
          let day = days[i];
          if (day) {
            if (day.classList.contains("selected")) {
              day.classList.remove("selected");
              day.classList.remove(self.backgroundColourClass);
            }
          }
        }
      }
    }
  },
  computed: {
    savedDatesToUse: {
      get() {
        return this.savedDates;
      },
      set(arrayOfSavedDates) {
        console.log('what"s going on?', arrayOfSavedDates);
      }
    },
    backgroundColourClass() {
      if (this.changeColour == "grey") {
        return "grey";
      } else {
        return "green";
      }
    },
    idToUse() {
      return `${this.UniqueId}`;
    },
    excludedDatesToUse() {
      return this.excludedDates;
    }
  },
  watch: {
    dates(data) {
      setTimeout(() => {
        this.showSelectedSaveDates();
        this.showAnyErrors();
      }, 30);
    },
    error(val) {
      let self = this;
      let ourCalendar = document.querySelector(`#${self.idToUse}`);
      if (ourCalendar) {
        if (val) {
          let inactiveDays = ourCalendar.querySelectorAll(".inactive.data");
          for (let i = 0; i < inactiveDays.length; i++) {
            let element = inactiveDays[i];
            element.classList.add("error");
          }
        } else {
          let inactiveDays = ourCalendar.querySelectorAll(".error");
          for (let i = 0; i < inactiveDays.length; i++) {
            let element = inactiveDays[i];
            element.classList.remove("error");
          }
        }
      }
    },
    reApplySavedDates(val) {
      setTimeout(() => {
        this.showSelectedSaveDates();
        if (this.savedDates.length == 0) {
          this.removeHighlightedDays();
        }
      }, 30);
    },
    excludedDates(val) {
      this.greyOutExcludedDates();
    }
  },
  async updated() {
    let self = this;
    let ourCalendar = document.querySelector(`#${self.idToUse}`);
    if (ourCalendar) {
      let days = ourCalendar.querySelector(".day");
      if (this.savedDates.length <= 0) {
        let selectedDays = ourCalendar.querySelectorAll(".selected");
        for (let i = 0; i < selectedDays.length; i++) {
          let element = selectedDays[i];
          if (element) {
            element.classList.remove("selected");
          }
        }
      }
      if (this.excludedDatesToUse) {
        if (this.excludedDatesToUse.length) {
          this.greyOutExcludedDates();
        }
      }
    }
  },
  async mounted() {
    console.log(this.dates, "dates we got");
    if (this.excludedDatesToUse) {
      if (this.excludedDatesToUse.length) {
        this.greyOutExcludedDates();
      }
    }
    if (this.savedDatesToUse.length > 0) {
      this.showSelectedSaveDates();
    }
  }
};
</script>
